<template>
  <modal
    :header-text="title"
    button-text="Сохранить"
    class="wtEdit"
    :hide-footer="add"
    :hide-cross="add"
    @action="save()"
    @close="close()"
  >
    <template v-if="add" v-slot:header-left>
      <div :class="$style.arrow" title="Назад" @click="add = false">
        <img
          src="@/assets/images/icons/navigation/arrow-left.svg"
          :class="$style.svg"
        >
      </div>
    </template>
    <div v-if="!add">
      <div :class="$style.buttonOpenContainer">
        <button :class="[$style.button, $style.buttonOpen]" @click="add = true">
          <div :class="$style.plus">
            +
          </div>
          Добавить интервал
        </button>
      </div>
      <scrollable-container :class="$style.scrollableContainer">
        <div v-for="range in shownRanges" :key="range.id" :class="$style.range">
          <div :class="$style.timeContainer">
            <div :class="$style.rangeItem">
              с
            </div>
            <div :class="[$style.rangeItem, $style.time]">
              {{ getTime(range.startTime) }}
            </div>
            <div :class="$style.rangeItem">
              по
            </div>
            <div :class="[$style.rangeItem, $style.time]">
              {{ getTime(range.endTime) }}
            </div>
          </div>
          <div :class="$style.rangeItem">
            <img
              title="Удалить"
              src="@/assets/images/icons/navigation/trash.svg"
              :class="$style.deleteIcon"
              @click="addToDelete(range)"
            >
          </div>
        </div>
      </scrollable-container>
    </div>
    <div v-if="add">
      <div :class="$style.title">
        Добавление нового интервала
      </div>
      <div :class="$style.timePicker">
        <div>Начало интервала</div>
        <date-picker v-model="startTime" type="time" />
      </div>
      <div :class="$style.timePicker">
        <div>Конец интервала</div>
        <date-picker v-model="endTime" type="time" />
      </div>
      <div :class="$style.buttonContainer">
        <button :class="[$style.button, $style.buttonAdd]" @click="addToAdd()">
          Добавить
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import DatePicker from 'vue2-datepicker';
import Modal from '@/components/common/modal/settings-modal.vue';
import { getTime } from '@/helpers/format-date';
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';
import { saveWorktime } from '@/api/methods/settings';

import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueToast);

export default {
  components: {
    DatePicker,
    Modal,
    ScrollableContainer,
  },
  props: ['timeArray', 'weekDay', 'profile', 'type'],
  data: () => ({
    toDelete: [],
    toAdd: [],
    shownRanges: [],
    add: false,
    startTime: new Date(),
    endTime: new Date(),
  }),
  computed: {
    title() {
      if (this.type) return 'Редактирование расписания';
      return 'Редактирование графика рабочего времени';
    },
  },
  watch: {
    timeArray(newArray, oldArray) {
      if (oldArray !== newArray) {
        this.shownRanges = newArray.slice();
      }
    },
  },
  created() {
    this.shownRanges = this.timeArray.slice();
    this.startTime.setHours(9);
    this.startTime.setMinutes(0);
    this.startTime.setSeconds(0);
    this.endTime.setHours(18);
    this.endTime.setMinutes(0);
    this.endTime.setSeconds(0);
  },
  methods: {
    close() {
      this.shownRanges = this.timeArray.slice();
      this.toDelete = [];
      this.toAdd = [];
      this.$emit('close');
    },
    async save() {
      if (this.type !== 'schedule') {
        await saveWorktime(this.toDelete, this.toAdd);
      } else {
        this.$emit('updateSchedule', {
          newRanges: this.shownRanges,
          day: this.weekDay,
        });
      }
      this.$emit('update');
      this.toDelete = [];
      this.toAdd = [];
      this.$emit('close');
    },
    getTime(date) {
      return getTime(date);
    },
    createDateAsUTC(date) {
      return new Date(
        Date.UTC(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes(),
          date.getSeconds(),
        ),
      );
    },
    addToAdd() {
      if (this.endTime > this.startTime) {
        this.toAdd.push({
          startTime: this.createDateAsUTC(this.startTime),
          endTime: this.createDateAsUTC(this.endTime),
          weekDay: this.weekDay,
          profile: this.profile,
        });
        this.shownRanges.push({
          startTime: this.startTime,
          endTime: this.endTime,
          weekDay: this.weekDay,
          profile: this.profile,
        });
        this.add = false;
      } else {
        Vue.$toast.open({
          message: 'Некорректный интервал',
          type: 'error',
        });
      }
    },
    addToDelete(range) {
      this.toDelete.push(range.id);
      this.shownRanges.splice(this.shownRanges.indexOf(range), 1);
    },
  },
};
</script>

<style >
.wtEdit .settings-modal_modal_3LaCC {
  width: 30% !important;
}
</style>

<style lang="scss" module>
.deleteIcon {
  cursor: pointer;
  margin-right: 5px;
}
.range {
  display: flex;
  margin: 5px 10px;
  cursor: default;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  &:hover {
    background: $light-gray;
  }
}
.rangeItem {
  margin: 5px;
}
.arrow {
  display: flex;
  cursor: pointer;
}
.svg {
  width: 30px;
}
.plus {
  font-weight: 700;
  font-size: 20px;
  padding: 0px 5px;
}
.button {
  margin: 5px;
  height: 30px;
  cursor: pointer;
  width: 90px;
  background: #2985bf;
  border: 2px solid #2985bf;
  border-radius: 2px;
  font: inherit;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}
.buttonOpen {
  display: flex;
  align-items: baseline;
  width: 173px;
  margin-bottom: 10px;
}
.buttonOpenContainer {
  display: flex;
  justify-content: center;
}
.timePicker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}
.title {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 500;
}
.buttonAdd {
  float: right;
  margin-right: 10px;
}
.time {
  font-size: 14px;
  font-weight: 500;
}
.timeContainer {
  display: flex;
}
</style>
