<template>
  <modal
    header-text="Настройки исключений"
    button-text="Сохранить"
    class="trExModal"
    :not-active="!appsChanged && !sslChanged"
    @action="save()"
    @close="discardChanges"
  >
    <tabset
      class="trExTabs"
      :tabs="tabs"
      :opened="true"
      :hide-arrow="true"
      :tab-style="tabStyle"
      @tabClick="openNewTab"
    />
    <div v-show="tabs[0].active">
      <vue-tags-input
        v-model="app"
        :tags="apps"
        :allow-edit-tags="false"
        placeholder="Добавить приложение"
        :class="$style.tags"
        @tags-changed="(newApps) => (apps = newApps)"
        @before-adding-tag="addApp"
        @before-deleting-tag="deleteExcl"
      />
    </div>
    <div v-show="tabs[1].active">
      <vue-tags-input
        v-model="sslItem"
        :tags="ssl"
        :allow-edit-tags="false"
        placeholder="Добавить сайт"
        :class="$style.tags"
        @tags-changed="(newSsl) => (ssl = newSsl)"
        @before-adding-tag="addSsl"
        @before-deleting-tag="deleteExcl"
      />
    </div>
  </modal>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import Modal from '@/components/common/modal/settings-modal.vue';
import Tabset from '@/components/common/tabs/single-view-tabs.vue';
import { getExceptions, editExceptions } from '@/api/methods/settings';

export default {
  components: {
    Modal,
    Tabset,
    VueTagsInput,
  },
  data: () => ({
    tabStyle: {
      padding: '10px',
      fontSize: '14px',
    },
    tabs: [
      {
        title: 'Исключение приложений',
        active: true,
      },
      {
        title: 'Исключение SSL',
        active: false,
      },
    ],
    apps: [],
    app: '',
    ssl: [],
    sslItem: '',
    appsChanged: false,
    sslChanged: false,
  }),
  computed: {
    selectedComputer() {
      return {
        type: this.$route.params.type,
        id: this.$route.params.computer,
      };
    },
  },
  async mounted() {
    await this.getTrafficExceptions();
  },
  methods: {
    async discardChanges() {
      this.$emit('close');
      await this.getTrafficExceptions();
    },
    async save() {
      if (this.appsChanged) {
        await editExceptions(
          this.$store.getters['settings/profile'],
          this.apps,
          2,
        );
      }
      if (this.sslChanged) {
        await editExceptions(
          this.$store.getters['settings/profile'],
          this.ssl,
          3,
        );
      }
      await this.getTrafficExceptions();
    },
    addApp(object) {
      if (!this.apps.find((tag) => tag.text === object.tag.text.trim())) {
        this.appsChanged = true;
        object.tag.text = object.tag.text.trim().toUpperCase();
        object.addTag();
      }
    },
    addSsl(object) {
      if (!this.ssl.find((tag) => tag.text === object.tag.text.trim())) {
        this.sslChanged = true;
        object.tag.text = object.tag.text.trim().toUpperCase();
        object.addTag();
      }
    },
    deleteExcl(object) {
      if (object.tag.stype === 2) {
        this.appsChanged = true;
        object.deleteTag();
      }
      if (object.tag.stype === 3) {
        this.sslChanged = true;
        object.deleteTag();
      }
    },
    getTags: (tags) => tags.map(({ sval, stype }) => ({ text: sval, stype })),
    async getTrafficExceptions() {
      this.appsChanged = false;
      this.sslChanged = false;
      const data = await getExceptions(
        this.selectedComputer.id,
        this.selectedComputer.type,
      );
      this.apps = this.getTags(data.result.apps);
      this.ssl = this.getTags(data.result.ssl);
    },
    openNewTab(index) {
      this.tabs.forEach((item) => {
        item.active = false;
      });

      this.tabs[index].active = true;
    },
  },
};
</script>

<style lang="scss" module>
.tags {
  margin: 20px;
}
</style>

<style>
.trExTabs .single-view-tabs_tabs_2UQRS {
  height: 40px !important;
}

.trExTabs .single-view-tabs_linkContainer_3LuJg {
  height: 35px !important;
}

.trExTabs a:hover {
  height: 35px !important;
}

.trExModal .settings-modal_modal_3LaCC {
  min-width: 560px !important;
}

.trExModal .modal_modalBody_1IJqV {
  padding: 10px 20px !important;
}
</style>
