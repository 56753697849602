<template>
  <div>
    <modal
      header-text="График рабочего времени"
      class="wtModal"
      :hide-footer="true"
      :hide-cross="false"
      @close="$emit('close')"
    >
      <div>
        <div :class="$style.profContainer">
          <div class="agentsSelect wt">
            <vue-select
              v-model="selectedProfile"
              :options="profiles"
              label="name"
              :reduce="(option) => option.id"
              :clearable="false"
              :searchable="false"
              :class="$style.select"
              @input="changeProfile"
            >
              <!-- eslint-disable-next-line vue/no-unused-vars  -->
              <template #no-options="{ search, searching, loading }">
                Нет профилей для выбора
              </template>
            </vue-select>
          </div>
          <button :class="$style.button" @click="openModal('addWtModal')">
            Добавить
          </button>
          <button
            :class="[$style.button, $style.buttonDelete]"
            @click="openModal('delWtModal')"
          >
            Удалить
          </button>
        </div>
        <div
          v-if="profiles.length > 0"
          :class="$style.settContainer"
          @click="checkCalendar()"
        >
          <div :class="$style.label">
            Использовать производственный календарь
          </div>
          <toggle-button
            v-model="useCalendar"
            :class="$style.toggle"
            :value="useCalendar"
            :sync="true"
            @click.native.prevent
          />
        </div>
        <div v-if="timelineReady" :class="$style.week">
          <weekday-timeline
            v-for="(day, index) in week"
            :key="index"
            :ref="'day' + index"
            :name="day.name"
            :day-index="index + 1"
            :day="day.timeline"
            :profile="selectedProfile"
            @update="updateWorktime"
          />
        </div>
      </div>
    </modal>
    <add-prof-modal
      id="addWtModal"
      :class="$style.addModal"
      type="wt"
      @close="closeModal('addWtModal')"
      @update="$emit('update')"
    />
    <del-prof-modal
      id="delWtModal"
      :class="$style.addModal"
      :selected="selected"
      type="wt"
      @close="closeModal('delWtModal')"
      @update="updateAfterDelete()"
    />
  </div>
</template>

<script>
import VueSelect from 'vue-select';
import Modal from '@/components/common/modal/settings-modal.vue';
import WeekdayTimeline from '@/views/settings/agents/agent/settings/weekday-timeline.vue';
import { getWorktime, changeCalendar } from '@/api/methods/settings';
import AddProfModal from '@/views/settings/agents/agent/settings/add-profile-modal.vue';
import DelProfModal from '@/views/settings/agents/agent/settings/del-profile-modal.vue';

export default {
  components: {
    Modal,
    VueSelect,
    WeekdayTimeline,
    AddProfModal,
    DelProfModal,
  },
  props: ['profiles'],
  data: () => ({
    selected: 1,
    timelineReady: false,
    week: [
      {
        name: 'Понедельник',
        timeline: [],
      },
      {
        name: 'Вторник',
        timeline: [],
      },
      {
        name: 'Среда',
        timeline: [],
      },
      {
        name: 'Четверг',
        timeline: [],
      },
      {
        name: 'Пятница',
        timeline: [],
      },
      {
        name: 'Суббота',
        timeline: [],
      },
      {
        name: 'Воскресенье',
        timeline: [],
      },
    ],
  }),

  computed: {
    selectedProfile: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    useCalendar: {
      get() {
        const prof = this.profiles.find((pr) => pr.id === this.selected);
        return prof.useCalendar;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  async created() {
    await this.getWorktime();
  },
  methods: {
    async checkCalendar() {
      const prof = this.profiles.find((pr) => pr.id === this.selected);
      this.$set(prof, 'useCalendar', !this.useCalendar);
      await changeCalendar(prof.id, prof.useCalendar);
      await this.getWorktime();
    },
    async updateAfterDelete() {
      this.$emit('update');
      await this.changeProfile(1);
    },
    async updateWorktime(weekDay) {
      await this.getWorktime();
      this.$refs[`day${weekDay}`][0].fetch();
    },
    closeModal(id) {
      document.getElementById(id).style.display = 'none';
    },

    openModal(id) {
      document.getElementById(id).style.display = 'flex';
    },
    async changeProfile(val) {
      this.selected = val;
      await this.getWorktime();
      for (let i = 0; i < 7; i++) {
        this.$refs[`day${i}`][0].fetch();
      }
    },
    async getWorktime() {
      if (this.selected) {
        const result = await getWorktime(this.selected);
        this.week.forEach((day) => {
          day.timeline = [];
        });
        result.result.forEach((element) => {
          this.week[parseInt(element.weekDay, 10) - 1].timeline.push(element);
        });
        this.timelineReady = true;
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" module>
.profContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0px 20px;
}

.week {
  margin: 20px 0px 0px 20px;
}

.select {
  margin: 10px 0px;
  // width: 60%;
}
.button {
  margin: 5px;
  height: 36px;
  cursor: pointer;
  width: 90px;
  background: #2985bf;
  border: 2px solid #2985bf;
  border-radius: 2px;
  font: inherit;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}
.buttonDelete {
  background: $red;
  border: $red;
}
.addModal {
  display: none;
  z-index: 4;
}

.settContainer {
  display: flex;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin: 0px 10px;
  &:hover {
    background: $light-gray;
  }
}

.label {
  margin: 0px 10px;
  font-size: 14px;
}

.toggle {
  z-index: 0;
}
</style>

<style >
.wtModal .settings-modal_modal_3LaCC {
  width: 70%;
}

.wt {
  width: 60%;
}
</style>
