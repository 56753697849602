<template>
  <div>
    <div :class="[$style.card]">
      <div
        v-if="fieldType('enabled')"
        :class="$style.settContainer"
        @click="check('enabled')"
      >
        <div :class="$style.label">
          Блокировать устройства
        </div>
        <toggle-button
          v-model="result.enabled"
          :value="result.enabled"
          :sync="true"
          @click.native.prevent
        />
      </div>
    </div>
    <div :class="[$style.card]">
      <div :class="$style.title">
        Блокируемые типы устройств
      </div>
      <label
        :class="$style.settContainer"
        @click="checkDevice(devices[0])"
        @click.stop.prevent
      >
        <div :class="$style.label">
          {{ devices[0].name }}
        </div>
        <toggle-button
          v-model="devices[0].checked"
          :value="devices[0].checked"
          :sync="true"
          :disabled="!result.enabled"
          @click.native.prevent
        />
      </label>
      <div :class="$style.options">
        <button
          :class="$style.button"
          :disabled="!result.enabled"
          @click="openModal('usb')"
        >
          Разрешенные USB устройства
        </button>
      </div>
      <label
        v-for="(device, index) in devices.slice(1)"
        :key="index"
        :class="$style.settContainer"
        @click="checkDevice(device)"
        @click.stop.prevent
      >
        <div :class="$style.label">
          {{ device.name }}
        </div>
        <toggle-button
          v-model="device.checked"
          :value="device.checked"
          :sync="true"
          :disabled="!result.enabled"
          @click.native.prevent
        />
      </label>
    </div>
    <usb-modal id="usb" :class="$style.modal" @close="closeModal('usb')" />
  </div>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button';
import Vue from 'vue';
import { getDriveSettings } from '@/api/methods/settings';
import UsbModal from '@/views/settings/agents/agent/settings/usb-modal.vue';

Vue.component('ToggleButton', ToggleButton);

export default {
  components: {
    UsbModal,
  },
  data: () => ({
    result: {},
    devices: [
      { name: 'USB Volumes', checked: false },
      { name: 'Floppy drives', checked: false },
      { name: 'CD ROMs', checked: false },
      { name: 'Bluetooth', checked: false },
      { name: 'IMAGING (фотоаппараты, сканеры, ...)', checked: false },
    ],
  }),
  computed: {
    selectedComputer() {
      return {
        type: this.$route.params.type,
        id: this.$route.params.computer,
      };
    },
    blockdev() {
      let binary = '';
      this.devices.forEach((dev) => {
        if (dev.checked) binary = `1${binary}`;
        else binary = `0${binary}`;
      });
      return parseInt(binary, 2);
    },
  },
  async created() {
    await this.getSettings();
  },
  async mounted() {
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/cancel') {
          await this.getSettings();
        }
      },
    });
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/paramChanged') {
          await this.getSettings();
        }
      },
    });
  },
  methods: {
    decodeDevices() {
      let code = this.result.blockdev.toString(2);
      for (let i = 0; i < 5 - code.length; i++) {
        code = `0${code}`;
      }
      for (let i = 0; i < code.length; i++) {
        this.devices[code.length - 1 - i].checked = !!+code[i];
      }
    },
    closeModal(id) {
      document.getElementById(id).style.display = 'none';
    },
    openModal(id) {
      document.getElementById(id).style.display = 'flex';
    },
    checkDevice(device) {
      if (this.result.enabled) {
        device.checked = !device.checked;

        this.$store.commit('settings/addDriveSettings', {
          key: 'blockdev',
          value: this.blockdev,
        });
      }
    },
    check(key) {
      this.$set(this.result, key, !this.result[key]);
      this.$store.commit('settings/addDriveSettings', {
        key,
        value: this.result[key],
      });
    },
    fieldType(field) {
      return typeof this.result[field] !== 'undefined';
    },
    async getSettings() {
      this.result = {};
      let type = 0;
      if (this.selectedComputer.id) {
        if (this.selectedComputer.type === 'folder') type = 2;

        this.promise = await getDriveSettings(
          this.selectedComputer.id,
          type,
        ).then(({ data }) => {
          if (data) {
            this.result = data.result;
          }
        });
        const settings = this.$store.getters['settings/driveSettings'];
        if (settings) {
          Object.entries(settings).forEach(([key, value]) => {
            this.result[key] = value;
          });
        }
      }
      this.decodeDevices();
    },
  },
};
</script>

<style lang="scss" module>
.settContainer {
  display: flex;
  padding: 10px 0px;
  margin: 0px 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: $light-gray;
  }
}

.label {
  margin: 0px 10px;
  font-size: 14px;
}

.input {
  margin-right: 5px;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
  width: 90px;
}

.card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 5px 0px 15px 22px;
  width: 92%;
  padding: 5px;
  > :not(:last-child) {
    border-bottom: 0.2px solid #eee;
  }
}

.disabled,
.disabled * {
  cursor: default;
}

.inline {
  display: inline-flex;
  align-items: center;
}

.select {
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
  width: 230px;
  cursor: pointer;
}

.title {
  padding: 20px 20px 10px 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  border: 0px !important;
}

.options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background: $light-gray;
  }
}

.button {
  margin: 15px;
  height: 30px;
  cursor: pointer;
  width: 240px;
  background: #2985bf;
  border: 2px solid #2985bf;
  border-radius: 2px;
  font: inherit;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  &:disabled {
    background: $branchColor;
    border: $branchColor;
  }
}

.modal {
  display: none;
  z-index: 3;
}
</style>
