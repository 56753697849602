<template>
  <modal
    header-text=""
    button-text="Удалить"
    @action="delProfile()"
    @close="$emit('close')"
  >
    <div :class="$style.inputContainer">
      Вы уверены, что хотите удалить профиль?
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/common/modal/settings-modal.vue';
import { deleteProdProfile, deleteWtProfile } from '@/api/methods/settings';

export default {
  components: { Modal },
  props: ['selected', 'type'],
  methods: {
    async delProfile() {
      if (this.type === 'prod') await deleteProdProfile(this.selected);
      if (this.type === 'wt') await deleteWtProfile(this.selected);
      this.$emit('update');
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" module>
.input {
  margin: 5px;
}
.inputContainer {
  font-size: 13px;
  margin: 5px;
}
</style>
