<template>
  <div>
    <loader v-if="settProfile === undefined" />
    <div v-if="settProfile !== undefined" :class="[$style.card]">
      <div
        v-if="fieldType('enabled')"
        :class="$style.settContainer"
        @click="check('enabled')"
      >
        <div :class="$style.label">
          Запоминать активность работы на компьютере
        </div>
        <toggle-button
          v-model="result.enabled"
          :class="$style.toggle"
          :value="result.enabled"
          :sync="true"
          @click.native.prevent
        />
      </div>

      <div :class="$style.settContainer">
        <label :class="$style.label">
          Считать простоем отсутствие активности более
        </label>
        <div :class="$style.inline">
          <input
            v-model.number="result.IdleTime"
            type="number"
            :class="$style.input"
            :disabled="!result.enabled"
            @change="change('IdleTime')"
          >
          мин
        </div>
      </div>
    </div>

    <div v-if="settProfile !== undefined" :class="[$style.card]">
      <div :class="$style.settContainer">
        <label :class="$style.label"> Профиль продуктивности </label>
        <div class="agentsSelect profile">
          <vue-select
            v-model="currentProdProfile"
            :options="profiles"
            label="name"
            :reduce="(option) => option.id"
            :clearable="false"
            :searchable="false"
            :class="$style.select"
            @input="changeCurrentProdProfile"
          >
            <!-- eslint-disable-next-line vue/no-unused-vars  -->
            <template #no-options="{ search, searching, loading }">
              Нет профилей для выбора
            </template>
          </vue-select>
        </div>
        <button :class="$style.button" @click="openModal('prof')">
          Управлять профилями продуктивности
        </button>
      </div>
    </div>

    <div v-if="settProfile !== undefined" :class="[$style.card]">
      <div :class="$style.settContainer">
        <label :class="$style.label"> График рабочего времени </label>
        <div class="agentsSelect profile">
          <vue-select
            v-model="currentWtProfile"
            :options="wtProfiles"
            label="name"
            :reduce="(option) => option.id"
            :clearable="false"
            :searchable="false"
            :class="$style.select"
            @input="changeCurrentWtProfile"
          >
            <!-- eslint-disable-next-line vue/no-unused-vars  -->
            <template #no-options="{ search, searching, loading }">
              Нет профилей для выбора
            </template>
          </vue-select>
        </div>
        <button :class="$style.button" @click="openModal('wt')">
          Управлять графиками рабочего времени
        </button>
      </div>
    </div>
    <prod-prof-modal
      id="prof"
      :class="$style.modal"
      :profiles="profiles"
      @close="closeModal('prof')"
      @update="getProductiveProfile()"
    />
    <wt-modal
      id="wt"
      :class="$style.modal"
      :profiles="wtProfiles"
      @close="closeModal('wt')"
      @update="getWtProfile()"
    />
  </div>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button';
import Vue from 'vue';
import VueSelect from 'vue-select';
import {
  getIdleSettings,
  getProdProfile,
  getWtProfile,
} from '@/api/methods/settings';
import ProdProfModal from '@/views/settings/agents/agent/settings/prod-prof-modal.vue';
import WtModal from '@/views/settings/agents/agent/settings/wt-modal.vue';
import Loader from '@/components/common/blocks/loader.vue';

Vue.component('ToggleButton', ToggleButton);

export default {
  components: {
    ProdProfModal,
    VueSelect,
    Loader,
    WtModal,
  },
  data: () => ({
    result: {},
    profiles: [],
    prodProfile: undefined,
    wtProfiles: [],
    wtProfile: undefined,
    currentProdProfile: undefined,
    currentWtProfile: undefined,
  }),
  computed: {
    selectedComputer() {
      return {
        type: this.$route.params.type,
        id: this.$route.params.computer,
      };
    },
    settProfile() {
      return this.$store.getters['settings/profile'];
    },
  },
  async created() {
    await this.getSettings();
    await this.getProductiveProfile();
    await this.getWtProfile();
  },
  async mounted() {
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/cancel') {
          await this.getSettings();
          this.currentProdProfile = this.prodProfile;
          this.currentWtProfile = this.wtProfile;
        }
      },
    });
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/paramChanged') {
          await this.getSettings();
          await this.getProductiveProfile();
          await this.getWtProfile();
        }
      },
    });
  },
  methods: {
    async getWtProfile() {
      if (this.settProfile) {
        const result = await getWtProfile(this.settProfile);
        if (result.result) {
          this.wtProfiles = result.result.profiles;
          this.wtProfile = result.result.selected;
          const current = this.$store.getters['settings/wtProfile'];
          if (current) this.currentWtProfile = current;
          else this.currentWtProfile = this.wtProfile;
        }
      }
    },
    changeCurrentProdProfile(val) {
      if (val) {
        this.$store.commit('settings/setProdProfile', val);
      }
    },
    changeCurrentWtProfile(val) {
      if (val) {
        this.$store.commit('settings/setWtProfile', val);
      }
    },
    async getProductiveProfile() {
      if (this.settProfile) {
        const result = await getProdProfile(this.settProfile);
        if (result.result) {
          this.profiles = result.result.profiles;
          this.prodProfile = result.result.selected;
          const current = this.$store.getters['settings/prodProfile'];
          if (current) this.currentProdProfile = current;
          else this.currentProdProfile = this.prodProfile;
        }
      }
    },
    closeModal(id) {
      document.getElementById(id).style.display = 'none';
    },
    openModal(id) {
      document.getElementById(id).style.display = 'flex';
    },
    change(field) {
      this.$store.commit('settings/addIdleSettings', {
        key: field,
        value: this.result[field],
      });
    },
    check(key) {
      this.$set(this.result, key, !this.result[key]);
      this.$store.commit('settings/addIdleSettings', {
        key,
        value: this.result[key],
      });
    },
    fieldType(field) {
      return typeof this.result[field] !== 'undefined';
    },
    async getSettings() {
      this.result = {};
      let type = 0;
      if (this.selectedComputer.id) {
        if (this.selectedComputer.type === 'folder') type = 2;

        this.promise = await getIdleSettings(
          this.selectedComputer.id,
          type,
        ).then(({ data }) => {
          if (data) {
            this.result = data.result;
          }
        });
        const settings = this.$store.getters['settings/idleSettings'];
        if (settings) {
          Object.entries(settings).forEach(([key, value]) => {
            this.result[key] = value;
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" module>
.settContainer {
  display: flex;
  padding: 10px 0px;
  margin: 0px 10px;
  justify-content: space-between;
  //width: 90%;
  align-items: center;
  cursor: pointer;
  //margin-left: 10px;
  &:hover {
    background: $light-gray;
  }
}

.label {
  margin: 0px 10px;
  font-size: 14px;
}

.input {
  margin-right: 5px;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
  width: 90px;
}

.card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 5px 0px 15px 22px;
  width: 92%;
  padding: 5px;
  > :not(:last-child) {
    border-bottom: 0.2px solid #eee;
  }
}

.disabled,
.disabled * {
  cursor: default;
}

.modal {
  display: none;
  z-index: 4;
}

.select {
  // width: 50%;
  margin: 15px;
  z-index: 0;
}

.button {
  margin: 5px;
  height: 36px;
  cursor: pointer;
  width: 190px;
  background: #2985bf;
  border: 2px solid #2985bf;
  border-radius: 2px;
  font: inherit;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  @media (max-width: 1770px) {
    height: 50px;
  }
}
.toggle {
  z-index: 0;
}
.inline {
  display: inline-flex;
  align-items: center;
}
</style>

<style >
.profile {
  width: 50%;
}
</style>
