<template>
  <div>
    <div :class="[$style.card]">
      <div :class="$style.settContainer">
        <div :class="$style.label">
          Путь для сохранения файлов на "шаре" сервера
        </div>
        <input
          v-model="result.FilesSharePath"
          type="text"
          :class="[$style.input, $style.longInput]"
          @input="change('FilesSharePath')"
        >
      </div>
      <div :class="$style.settContainer">
        <div :class="$style.label">
          Резервный путь на локальном компьютере
        </div>
        <input
          v-model="result.FilesReservePath"
          type="text"
          :class="[$style.input, $style.longInput]"
          @input="change('FilesReservePath')"
        >
      </div>
      <div :class="$style.settContainer">
        <div :class="$style.label">
          Логин для подключения
        </div>
        <input
          v-model="result.ShareLogin"
          type="text"
          :class="[$style.input, $style.longInput]"
          @input="change('ShareLogin')"
        >
      </div>
      <div :class="$style.settContainer">
        <div :class="$style.label">
          Пароль для подключения
        </div>
        <input
          v-model="result.SharePassword"
          type="password"
          :class="[$style.input, $style.longInput]"
          @input="change('SharePassword')"
        >
      </div>
      <div :class="$style.settContainer">
        <label :class="$style.label">
          Прекращать запись, если осталось свободного места менее
        </label>
        <div :class="$style.inline">
          <input
            v-model.number="result.MinFreeMemory"
            type="number"
            :class="$style.input"
            @change="change('MinFreeMemory')"
          >
          GB
        </div>
      </div>
    </div>
    <div :class="[$style.card]">
      <div :class="[$style.card]">
        <div
          v-if="fieldType('Video')"
          :class="$style.settContainer"
          @click="changeNested('Enabled', 'Video', true, false)"
        >
          <div :class="$style.label">
            Записывать видео
          </div>
          <toggle-button
            v-model="result.Video.Enabled"
            :class="$style.toggle"
            :value="result.Video.Enabled"
            :sync="true"
            @click.native.prevent
          />
        </div>
        <div
          v-if="fieldType('Video')"
          :class="$style.settContainer"
          @click="
            changeNested('RecordSound', 'Video', true, !result.Video.Enabled)
          "
        >
          <div :class="$style.label">
            Видео со звуком
          </div>
          <toggle-button
            v-model="result.Video.RecordSound"
            :class="$style.toggle"
            :value="result.Video.RecordSound"
            :sync="true"
            :disabled="!result.Video.Enabled"
            @click.native.prevent
          />
        </div>
        <div v-if="fieldType('Video')" :class="$style.settContainer">
          <label :class="$style.label"> Качество видео </label>
          <select
            v-model="result.VideoQuality"
            :class="$style.select"
            :disabled="!result.Video.Enabled"
            @change="change('VideoQuality')"
          >
            <option
              v-for="option in qualityOptions"
              :key="option.id"
              :value="option.id"
            >
              {{ option.name }}
            </option>
          </select>
        </div>
        <div :class="$style.settContainer">
          <div :class="$style.label">
            Видео устройство
          </div>
          <input
            v-model="videoDevice"
            type="text"
            :class="[$style.input, $style.longInput]"
            disabled
          >
        </div>
      </div>
      <div :class="[$style.card]">
        <div
          v-if="fieldType('Audio')"
          :class="$style.settContainer"
          @click="changeNested('Enabled', 'Audio', true, false)"
        >
          <div :class="$style.label">
            Записывать звук с микрофона
          </div>
          <toggle-button
            v-model="result.Audio.Enabled"
            :class="$style.toggle"
            :value="result.Audio.Enabled"
            :sync="true"
            @click.native.prevent
          />
        </div>
        <div v-if="fieldType('Audio')" :class="$style.settContainer">
          <label :class="$style.label"> Качество звука </label>
          <select
            v-model="result.AudioQuality"
            :class="$style.select"
            :disabled="!result.Audio.Enabled"
            @change="change('AudioQuality')"
          >
            <option
              v-for="option in qualityOptions"
              :key="option.id"
              :value="option.id"
            >
              {{ option.name }}
            </option>
          </select>
        </div>
        <div :class="$style.settContainer">
          <div :class="$style.label">
            Аудио устройство
          </div>
          <input
            v-model="videoDevice"
            type="text"
            :class="[$style.input, $style.longInput]"
            disabled
          >
        </div>
      </div>
      <div v-if="result.Video" :class="[$style.card]">
        <div :class="$style.settContainer">
          <label :class="$style.label">
            Продолжительность аудио/видео файла
          </label>
          <div :class="$style.inline">
            <input
              v-model.number="result.Video.Duration"
              type="number"
              :class="$style.input"
              :disabled="!result.Audio.Enabled && !result.Video.Enabled"
              @change="
                changeNested(
                  'Duration',
                  'Video',
                  false,
                  !result.Audio.Enabled && !result.Video.Enabled
                )
              "
            >
            мин
          </div>
        </div>
      </div>
    </div>
    <div :class="[$style.card]">
      <div
        v-if="fieldType('Screen')"
        :class="[$style.settContainer]"
        @click="changeNested('Enabled', 'Screen', true, false)"
      >
        <div :class="[$style.label, $style.idle]">
          Делать снимки с вебкамеры каждые
        </div>

        <div :class="$style.flex">
          <input
            v-model.number="result.Screen.Interval"
            type="number"
            :class="$style.input"
            :disabled="!result.Screen.Enabled"
            @change="
              changeNested('Interval', 'Screen', false, !result.Screen.Enabled)
            "
            @click.stop
          >
          <div :class="$style.min">
            сек
          </div>
        </div>
        <toggle-button
          v-model="result.Screen.Enabled"
          :class="$style.toggle"
          :value="result.Screen.Enabled"
          :sync="true"
          @click.native.prevent
        />
      </div>
      <div
        v-if="fieldType('UseSchedule')"
        :class="$style.settContainer"
        @click="check('UseSchedule')"
      >
        <div :class="$style.label">
          Запись с веб-камеры по расписанию
        </div>
        <toggle-button
          v-model="result.UseSchedule"
          :class="$style.toggle"
          :value="result.UseSchedule"
          :sync="true"
          @click.native.prevent
        />
      </div>
      <div :class="$style.options">
        <button
          :class="$style.button"
          :disabled="!result.UseSchedule"
          @click="openModal('schedule')"
        >
          Редактировать расписание
        </button>
      </div>
    </div>
    <schedule
      v-if="timelineReady"
      id="schedule"
      :class="$style.modal"
      :timeline="timeline"
      @close="closeModal('schedule')"
      @updateSchedule="updateSchedule"
    />
  </div>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button';
import Vue from 'vue';
import { getWebcamSettings } from '@/api/methods/settings';
import Schedule from '@/views/settings/agents/agent/settings/schedule.vue';

Vue.component('ToggleButton', ToggleButton);

export default {
  components: {
    Schedule,
  },
  data: () => ({
    timeline: [],
    timelineReady: false,
    result: {},
    qualityOptions: [
      { name: 'Низкое', id: 0 },
      { name: 'Среднее', id: 1 },
      { name: 'Высокое', id: 2 },
    ],
  }),
  computed: {
    selectedComputer() {
      return {
        type: this.$route.params.type,
        id: this.$route.params.computer,
      };
    },
    videoDevice() {
      if (
        this.result.SelectedDeviceVideo
        && this.result.SelectedDeviceVideo.length > 0
      ) {
        return this.result.SelectedDeviceVideo;
      }
      return 'Не выбрано';
    },
    audioDevice() {
      if (
        this.result.SelectedDeviceAudio
        && this.result.SelectedDeviceAudio.length > 0
      ) {
        return this.result.SelectedDeviceAudio;
      }
      return 'Не выбрано';
    },
  },
  async created() {
    await this.getSettings();
  },
  async mounted() {
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/cancel') {
          await this.getSettings();
        }
      },
    });
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/paramChanged') {
          await this.getSettings();
        }
      },
    });
  },
  methods: {
    getMin(date) {
      let res = date.getMinutes();
      if (res < 10) res = `0${res}`;
      return res;
    },
    getSec(date) {
      let res = date.getSeconds();
      if (res < 10) res = `0${res}`;
      return res;
    },
    updateSchedule(newObject) {
      const res = ['', '', '', '', '', '', ''];
      let time = this.timeline.slice(0);
      time = time.filter(
        (obj) => obj.weekDay !== newObject.day.toString()
          && obj.weekDay !== newObject.day,
      );
      newObject.newRanges.forEach((period) => time.push(period));
      this.timeline = time;
      this.timeline.forEach((period) => {
        const timeString = `${period.startTime.getHours()}-${this.getMin(
          period.startTime,
        )}-${this.getSec(
          period.startTime,
        )}|${period.endTime.getHours()}-${this.getMin(
          period.endTime,
        )}-${this.getSec(period.endTime)};`;
        res[parseInt(period.weekDay, 10) - 1] = res[parseInt(period.weekDay, 10) - 1] + timeString;
      });
      this.$store.commit('settings/addWebcamSettings', {
        key: 'ScheduleArray',
        value: res,
      });
    },
    closeModal(id) {
      document.getElementById(id).style.display = 'none';
    },
    openModal(id) {
      document.getElementById(id).style.display = 'flex';
    },
    change(field) {
      this.$store.commit('settings/addWebcamSettings', {
        key: field,
        value: this.result[field],
      });
    },
    check(key) {
      this.$set(this.result, key, !this.result[key]);
      this.$store.commit('settings/addWebcamSettings', {
        key,
        value: this.result[key],
      });
    },
    changeNested(key, name, check, disabled) {
      if (!disabled) {
        let { result } = this;
        let path = 'settings/addWebcamSettings';
        if (name === 'Video') {
          result = this.result.Video;
          path = 'settings/addVideoSettings';
        }
        if (name === 'Audio') {
          result = this.result.Audio;
          path = 'settings/addAudioSettings';
        }
        if (name === 'Screen') {
          result = this.result.Screen;
          path = 'settings/addScreenSettings';
        }
        if (check) this.$set(result, key, !result[key]);
        this.$store.commit(path, {
          key,
          value: result[key],
        });
      }
    },
    fieldType(field) {
      return typeof this.result[field] !== 'undefined';
    },
    async getSettings() {
      this.result = {};
      let type = 0;
      if (this.selectedComputer.id) {
        if (this.selectedComputer.type === 'folder') type = 2;

        this.promise = await getWebcamSettings(
          this.selectedComputer.id,
          type,
        ).then(({ data }) => {
          if (data) {
            data.result.Video.Duration /= 60;
            this.result = data.result;
          }
        });
        const settings = this.$store.getters['settings/webcamSettings'];
        if (settings) {
          Object.entries(settings).forEach(([key, value]) => {
            this.result[key] = value;
          });
        }
        this.timeline = [];
        const res = [];
        Object.entries(this.result.ScheduleArray).forEach(([key, value]) => {
          const arr = value.split(';');
          arr.pop();
          arr.forEach((item) => {
            const time = item.split('|');
            res.push({
              startTime: this.getTimeFromStr(time[0]),
              endTime: this.getTimeFromStr(time[1]),
              weekDay: (parseInt(key[key.length - 1], 10) + 1).toString(),
            });
          });
        });
        this.timeline = res;
        this.timelineReady = true;
      }
    },
    getTimeFromStr(str) {
      const [hours, minutes, seconds] = str.split('-');
      const d = new Date();
      d.setHours(hours);
      d.setMinutes(minutes);
      d.setSeconds(seconds);
      return d;
    },
  },
};
</script>

<style lang="scss" module>
.settContainer {
  display: flex;
  padding: 10px 0px;
  margin: 0px 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: $light-gray;
  }
}

.label {
  margin: 0px 10px;
  font-size: 14px;
}

.input {
  margin-right: 5px;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
  width: 90px;
}

.longInput {
  width: 40%;
}

.card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 5px 0px 15px 22px;
  width: 92%;
  padding: 5px;
  > :not(:last-child) {
    border-bottom: 0.2px solid #eee;
  }
}

.disabled,
.disabled * {
  cursor: default;
}

.inline {
  display: inline-flex;
  align-items: center;
}

.select {
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
  width: 41%;
  cursor: pointer;
}

.idle {
  width: 300px;
  margin: 20px 10px;
  line-height: 20px;
}
.flex {
  display: flex;
}
.min {
  display: flex;
  align-items: center;
  margin: 0px 5px;
}

.modal {
  display: none;
  z-index: 4;
}

.options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background: $light-gray;
  }
}

.button {
  margin: 15px;
  height: 30px;
  cursor: pointer;
  width: 265px;
  background: #2985bf;
  border: 2px solid #2985bf;
  border-radius: 2px;
  font: inherit;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  &:disabled {
    background: $branchColor;
    border: $branchColor;
  }
}

.toggle {
  z-index: 0;
}
</style>
