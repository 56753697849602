<template>
  <div>
    <modal
      header-text="Расписание записи с веб-камеры"
      class="wtModal"
      :hide-footer="true"
      :hide-cross="false"
      @close="$emit('close')"
    >
      <div>
        <div v-if="timelineReady" :class="$style.week">
          <weekday-timeline
            v-for="(day, index) in week"
            :key="index"
            :ref="'day' + index"
            :name="day.name"
            :day-index="index + 1"
            :day="day.timeline"
            type="schedule"
            @update="updateWorktime"
            @updateSchedule="(value) => $emit('updateSchedule', value)"
          />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/common/modal/settings-modal.vue';
import WeekdayTimeline from '@/views/settings/agents/agent/settings/weekday-timeline.vue';

export default {
  components: {
    Modal,
    WeekdayTimeline,
  },
  props: ['timeline'],
  data: () => ({
    timelineReady: false,
    week: [
      {
        name: 'Понедельник',
        timeline: [],
      },
      {
        name: 'Вторник',
        timeline: [],
      },
      {
        name: 'Среда',
        timeline: [],
      },
      {
        name: 'Четверг',
        timeline: [],
      },
      {
        name: 'Пятница',
        timeline: [],
      },
      {
        name: 'Суббота',
        timeline: [],
      },
      {
        name: 'Воскресенье',
        timeline: [],
      },
    ],
  }),
  watch: {
    timeline(newArray, oldArray) {
      if (oldArray !== newArray) {
        this.getWorktime();
      }
    },
  },
  async created() {
    await this.getWorktime();
  },
  methods: {
    async updateWorktime(weekDay) {
      await this.getWorktime();
      this.$refs[`day${weekDay}`][0].fetch();
    },
    closeModal(id) {
      document.getElementById(id).style.display = 'none';
    },
    openModal(id) {
      document.getElementById(id).style.display = 'flex';
    },
    async getWorktime() {
      for (let i = 0; i < this.week.length; i++) this.week[i].timeline = [];
      this.timeline.forEach((element) => {
        this.week[parseInt(element.weekDay, 10) - 1].timeline.push(element);
      });
      this.timelineReady = true;
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" module>
.profContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0px 20px;
}

.week {
  margin: 20px 0px 0px 20px;
}

.select {
  margin: 10px 0px;
  // width: 60%;
}
.button {
  margin: 5px;
  height: 36px;
  cursor: pointer;
  width: 90px;
  background: #2985bf;
  border: 2px solid #2985bf;
  border-radius: 2px;
  font: inherit;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}
.buttonDelete {
  background: $red;
  border: $red;
}
.addModal {
  display: none;
  z-index: 4;
}

.settContainer {
  display: flex;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin: 0px 10px;
  &:hover {
    background: $light-gray;
  }
}

.label {
  margin: 0px 10px;
  font-size: 14px;
}

.toggle {
  z-index: 0;
}
</style>

<style >
.wtModal .settings-modal_modal_3LaCC {
  width: 70%;
}

.wt {
  width: 60%;
}
</style>
