<template>
  <div>
    <div :class="[$style.card]">
      <label
        v-for="(value, index) in settings"
        :key="index"
        :class="$style.settContainer"
        @click="check(value.field)"
        @click.stop.prevent
      >
        <div :class="$style.label">
          {{ value.name }}
        </div>
        <toggle-button
          v-model="result[value.field]"
          :value="result[value.field]"
          :sync="true"
          @click.native.prevent
        />
      </label>
    </div>
    <div :class="[$style.card]">
      <div :class="$style.settContainer">
        <div :class="$style.label">
          Контролируемые порты
        </div>
        <vue-tags-input
          v-model="controlPort"
          :tags="controlPorts"
          :allow-edit-tags="false"
          placeholder="Добавить порт"
          :class="$style.tags"
          @tags-changed="(newContrPorts) => (controlPorts = newContrPorts)"
          @before-adding-tag="addPort"
          @before-deleting-tag="deletePort"
        />
      </div>
      <div :class="$style.options">
        <button :class="$style.button" @click="openModal('exceptions')">
          Исключения из фильтрации трафика
        </button>
      </div>
    </div>
    <traffic-exceptions
      id="exceptions"
      :class="$style.modal"
      @close="closeModal('exceptions')"
    />
  </div>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button';
import Vue from 'vue';
import VueTagsInput from '@johmun/vue-tags-input';
import { getInetSettings } from '@/api/methods/settings';
import TrafficExceptions from '@/views/settings/agents/agent/settings/traffic-exceptions.vue';

Vue.component('ToggleButton', ToggleButton);

export default {
  components: {
    VueTagsInput,
    TrafficExceptions,
  },
  data: () => ({
    result: {},
    settings: [
      { name: 'Запоминать e-mail почтовых клиентов', field: 'mail' },
      { name: 'Контролировать почту MS Exchange Server', field: 'outlook' },
      { name: 'Skype', field: 'skype' },
      { name: 'WhatsApp web & Telegram web', field: 'telegram' },
      { name: 'Контролировать HTTPS трафик', field: 'https' },
      { name: 'Блокировать web почту', field: 'blockWebMail' },
      { name: 'Блокировать клиентов облачных дисков', field: 'blockCloud' },
    ],
    controlPort: '',
    controlPorts: [],
  }),
  computed: {
    selectedComputer() {
      return {
        type: this.$route.params.type,
        id: this.$route.params.computer,
      };
    },
  },
  created() {
    this.getSettings();
  },
  async mounted() {
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/cancel') {
          await this.getSettings();
        }
      },
    });
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/paramChanged') {
          await this.getSettings();
        }
      },
    });
  },
  methods: {
    closeModal(id) {
      document.getElementById(id).style.display = 'none';
    },
    openModal(id) {
      document.getElementById(id).style.display = 'flex';
    },
    addPort(object) {
      if (
        !this.controlPorts.find((tag) => tag.text === object.tag.text.trim())
      ) {
        let ports = '';
        this.controlPorts.forEach((port) => {
          ports = `${ports + port.text};`;
        });
        ports += object.tag.text.trim();
        object.addTag();
        this.$store.commit('settings/addInetSettings', {
          key: 'ports',
          value: ports,
        });
      }
    },
    deletePort(object) {
      object.deleteTag();
      let ports = '';
      this.controlPorts.forEach((port) => {
        ports = `${ports + port.text};`;
      });
      this.$store.commit('settings/addInetSettings', {
        key: 'ports',
        value: ports.slice(0, -1),
      });
    },
    check(key) {
      this.$set(this.result, key, !this.result[key]);
      this.$store.commit('settings/addInetSettings', {
        key,
        value: this.result[key],
      });
    },
    getTags: (tags) => tags.map((string) => ({ text: string })),
    async getSettings() {
      let type = 0;
      if (this.selectedComputer.id) {
        if (this.selectedComputer.type === 'folder') type = 2;
        this.promise = await getInetSettings(
          this.selectedComputer.id,
          type,
        ).then(({ data }) => {
          if (data) {
            this.result = data.result;
          }
        });
        const settings = this.$store.getters['settings/inetSettings'];
        if (settings) {
          Object.entries(settings).forEach(([key, value]) => {
            this.result[key].value = value.value;
          });
        }
        const tags = this.result.ports.split(';');
        if (tags[tags.length - 1] === '') tags.pop();
        this.controlPorts = this.getTags(tags);
      }
    },
  },
};
</script>

<style lang="scss" module>
.settContainer {
  display: flex;
  padding: 10px 0px;
  margin: 0px 10px;
  justify-content: space-between;
  // width: 90%;
  align-items: center;
  cursor: pointer;
  // margin-left: 10px;
  &:hover {
    background: $light-gray;
  }
}

.label {
  margin: 0px 10px;
  font-size: 14px;
}

.card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 5px 0px 15px 22px;
  width: 92%;
  padding: 5px;
  > :not(:last-child) {
    border-bottom: 0.2px solid #eee;
  }
}

.disabled,
.disabled * {
  cursor: default;
}

.tags {
  margin: 20px;
}

.title {
  margin: 20px 20px 10px 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
}

.options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background: $light-gray;
  }
}

.button {
  margin: 15px;
  height: 30px;
  cursor: pointer;
  width: 265px;
  background: #2985bf;
  border: 2px solid #2985bf;
  border-radius: 2px;
  font: inherit;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  &:disabled {
    background: $branchColor;
    border: $branchColor;
  }
}

.modal {
  display: none;
  z-index: 3;
}
</style>

<style lang="css">
div .ti-tag[data-v-61d92e31] {
  background-color: #e3f2fd;
  color: #039be5;
  font-size: 12px;
  border: 1px solid transparent;
  border-radius: 2px;
}
.ti-disabled .ti-tag[data-v-61d92e31] {
  background-color: #f5f5f5;
  color: #9a99a2;
  font-size: 12px;
  border: 1px solid transparent;
  border-radius: 2px;
}
div .vue-tags-input[data-v-61d92e31] {
  max-width: 100%;
}
</style>
