<template>
  <div>
    <div :class="$style.weekDay">
      <div :class="$style.weekDayName">
        {{ name }}
      </div>
      <div :class="$style.container">
        <table :class="$style.ruler">
          <tr class="ruler">
            <td
              v-for="number in hoursNumber.hours"
              :key="number"
              :class="$style.hour"
            >
              {{ getRulerTime(number) }}
            </td>
          </tr>
        </table>
        <div v-if="day.length > 0" :class="$style.timeline">
          <svg :class="$style.svg">
            <g v-for="(event, index) in timeline" :key="event.start + index">
              <rect
                :width="event.width + '%'"
                :x="event.start + '%'"
                :class="[$style.bar]"
              />
            </g>
          </svg>
        </div>
      </div>
      <div
        :class="[$style.weekDayName, $style.editContainer]"
        title="Редактировать"
      >
        <img
          src="@/assets/images/icons/navigation/tasks.svg"
          :class="$style.editImg"
          @click="openModal('edit' + name)"
        >
      </div>
    </div>
    <edit-wt-modal
      :id="'edit' + name"
      :class="$style.addModal"
      :time-array="day"
      :week-day="dayIndex"
      :profile="profile"
      :type="type"
      @close="closeModal('edit' + name)"
      @update="$emit('update', dayIndex - 1)"
      @updateSchedule="(value) => $emit('updateSchedule', value)"
    />
  </div>
</template>

<script>
import { getTime, convertSecondsToDays } from '@/helpers/format-date';
import EditWtModal from '@/views/settings/agents/agent/settings/edit-wt-modal.vue';

export default {
  components: {
    EditWtModal,
  },
  props: ['name', 'day', 'dayIndex', 'profile', 'type'],
  data: () => ({ timeline: [] }),
  computed: {
    maxTime() {
      return this.day
        .slice(0)
        .reduce((prev, current) => (prev.endTime > current.endTime ? prev : current));
    },
    minTime() {
      return this.day
        .slice(0)
        .reduce((prev, current) => (prev.startTime < current.startTime ? prev : current));
    },
    hoursNumber() {
      let startTime = 8;
      let finishTime = 20;
      if (this.day.length > 0) {
        startTime = new Date(this.minTime.startTime).getHours() - 1;
        finishTime = new Date(this.maxTime.endTime).getHours() + 1;
      }
      const hoursNumber = finishTime - startTime + 1;
      const hoursArray = [];
      for (let i = startTime; i <= finishTime; i++) {
        hoursArray.push(i);
      }
      return {
        start: startTime,
        finish: finishTime,
        number: hoursNumber,
        hours: hoursArray,
      };
    },
    oneSecondPercent() {
      return 1 / (this.hoursNumber.number * 36);
    },
  },
  created() {
    this.fetch();
  },

  methods: {
    closeModal(id) {
      document.getElementById(id).style.display = 'none';
    },
    openModal(id) {
      document.getElementById(id).style.display = 'flex';
    },
    getRulerTime(hours) {
      if (hours === 24) return '00:00';
      if (hours === -1) return '23:00';
      return `${hours}:00`;
    },
    convertSecondsToDays(secNum) {
      return convertSecondsToDays(secNum);
    },
    getTime(date) {
      return getTime(date);
    },
    getDuration(dateStart, dateEnd) {
      const start = new Date(dateStart).getTime();
      const end = new Date(dateEnd).getTime();
      return (end - start) / 1000;
    },
    getStartTime(date) {
      const startTime = new Date(date);
      const startHour = startTime.getHours() - this.hoursNumber.start;
      const startMinute = startTime.getMinutes();
      const startSecond = startTime.getSeconds();

      return (
        (startHour * 3600 + startMinute * 60 + startSecond)
        * this.oneSecondPercent
      );
    },

    fetch() {
      if (this.day.length === 0) return [];

      this.day = this.day.sort((a, b) => {
        if (a.startTime > b.startTime) {
          return 1;
        }
        if (a.startTime < b.startTime) {
          return -1;
        }

        return 0;
      });
      const timelineArray = this.day.slice(0);

      this.timeline = [];
      for (let i = 0; i < timelineArray.length; i++) {
        timelineArray[i].duration = this.getDuration(
          timelineArray[i].startTime,
          timelineArray[i].endTime,
        );
        if (timelineArray[i].duration < 0) timelineArray[i].duration = 0;
        this.timeline.push({
          width: timelineArray[i].duration * this.oneSecondPercent,
          start: this.getStartTime(timelineArray[i].startTime),
        });
      }
      return this.timeline;
    },
  },
};
</script>

<style lang="scss" module>
.weekDay {
  display: flex;
  width: 100%;
}
.ruler {
  width: 100%;
  margin-bottom: 20px;
  table-layout: fixed;
}
.hour {
  border: 1px solid $borderColor;
  height: 60px;
  color: #7e7e7e;
  font-weight: 300;
  font-size: 12px;
  text-align: left;
  padding-left: 7px;
  padding-top: 7px;
}
.timeline {
  margin-top: -52px;
  height: 40px;
  width: 100%;
}
.svg {
  width: 100%;
  height: 23px;
  overflow: visible;
}
.bar {
  height: 23px;
  fill: #2985bf;
}
.text {
  font-size: 12px;
}
.container {
  width: 80%;
}
.weekDayName {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
}
.editContainer {
  width: 5%;
}
.editImg {
  cursor: pointer;
}
.addModal {
  display: none;
  z-index: 4;
}
</style>
