<template>
  <div>
    <div :class="[$style.card]">
      <div
        v-if="fieldType('enabled')"
        :class="$style.settContainer"
        @click="check('enabled')"
      >
        <div :class="$style.label">
          Делать теневую копию файлов, копируемых на USB накопители
        </div>
        <toggle-button
          v-model="result.enabled"
          :value="result.enabled"
          :sync="true"
          @click.native.prevent
        />
      </div>

      <div
        v-if="fieldType('clouddisk')"
        :class="$style.settContainer"
        @click="check('clouddisk')"
      >
        <div :class="$style.label">
          Делать копию файлов, копируемых на облачные диски
        </div>
        <toggle-button
          v-model="result.clouddisk"
          :value="result.clouddisk"
          :sync="true"
          @click.native.prevent
        />
      </div>
    </div>
    <div :class="[$style.card]">
      <div :class="$style.settContainer">
        <label :class="$style.label">
          Максимальный размер копируемого файла
        </label>
        <div :class="$style.inline">
          <input
            v-model.number="result.max_size"
            type="number"
            :class="$style.input"
            :disabled="!result.enabled && !result.clouddisk"
            @change="change('max_size')"
          >
          МБ
        </div>
      </div>
      <div :class="$style.settContainer">
        <label :class="$style.label"> Выделить для хранения файлов </label>
        <div :class="$style.inline">
          <input
            v-model.number="result.max_dsize"
            type="number"
            :class="$style.input"
            :disabled="!result.enabled && !result.clouddisk"
            @change="change('max_dsize')"
          >
          МБ
        </div>
      </div>
      <div :class="$style.settContainer">
        <label :class="$style.label"> При переполнении </label>
        <select
          v-model="result.clear"
          :class="$style.select"
          :disabled="!result.enabled && !result.clouddisk"
          @change="change('clear')"
        >
          <option v-for="option in options" :key="option.id" :value="option.id">
            {{ option.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button';
import Vue from 'vue';
import { getShadowCopySettings } from '@/api/methods/settings';

Vue.component('ToggleButton', ToggleButton);

export default {
  data: () => ({
    result: {},
    options: [
      { name: 'Не писать новые файлы', id: 0 },
      { name: 'Перезаписывать поверх старых', id: 1 },
    ],
  }),
  computed: {
    selectedComputer() {
      return {
        type: this.$route.params.type,
        id: this.$route.params.computer,
      };
    },
  },
  async created() {
    await this.getSettings();
  },
  async mounted() {
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/cancel') {
          await this.getSettings();
        }
      },
    });
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/paramChanged') {
          await this.getSettings();
        }
      },
    });
  },
  methods: {
    change(field) {
      this.$store.commit('settings/addShadowSettings', {
        key: field,
        value: this.result[field],
      });
    },
    check(key) {
      this.$set(this.result, key, !this.result[key]);
      this.$store.commit('settings/addShadowSettings', {
        key,
        value: this.result[key],
      });
    },
    fieldType(field) {
      return typeof this.result[field] !== 'undefined';
    },
    async getSettings() {
      this.result = {};
      let type = 0;
      if (this.selectedComputer.id) {
        if (this.selectedComputer.type === 'folder') type = 2;

        this.promise = await getShadowCopySettings(
          this.selectedComputer.id,
          type,
        ).then(({ data }) => {
          if (data) {
            this.result = data.result;
          }
        });
        const settings = this.$store.getters['settings/shadowSettings'];
        if (settings) {
          Object.entries(settings).forEach(([key, value]) => {
            this.result[key] = value;
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" module>
.settContainer {
  display: flex;
  padding: 10px 0px;
  margin: 0px 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: $light-gray;
  }
}

.label {
  margin: 0px 10px;
  font-size: 14px;
}

.input {
  margin-right: 5px;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
  width: 90px;
}

.card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 5px 0px 15px 22px;
  width: 92%;
  padding: 5px;
  > :not(:last-child) {
    border-bottom: 0.2px solid #eee;
  }
}

.disabled,
.disabled * {
  cursor: default;
}

.inline {
  display: inline-flex;
  align-items: center;
}

.select {
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
  width: 230px;
  cursor: pointer;
}
</style>
