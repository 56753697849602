<template>
  <div>
    <div :class="$style.card">
      <div
        v-if="fieldType('enabled')"
        :class="[$style.settContainer, $style.toggle]"
        @click="check('enabled')"
      >
        <div :class="$style.label">
          Делать скриншоты экрана
        </div>
        <toggle-button
          v-model="result.enabled"
          :value="result.enabled"
          :sync="true"
          @click.native.prevent
        />
      </div>
    </div>
    <div :class="$style.card">
      <div :class="$style.settContainer">
        <label :class="$style.label"> Интервал </label>
        <div :class="$style.inline">
          <input
            v-model.number="result.interval"
            type="number"
            :class="$style.input"
            :disabled="!result.enabled"
            @change="change('interval')"
          >
          <select
            v-model="result.interval_type"
            :class="$style.select"
            :disabled="!result.enabled"
            @change="change('interval_type')"
          >
            <option
              v-for="option in options"
              :key="option.id"
              :value="option.id"
            >
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>

      <div
        v-if="fieldType('screen_on_win_act')"
        :class="[$style.settContainer, $style.toggle]"
        @click="check('screen_on_win_act')"
      >
        <div :class="$style.label">
          Выполнять скриншот при смене окон
        </div>
        <toggle-button
          v-model="result.screen_on_win_act"
          :value="result.screen_on_win_act"
          :sync="true"
          :disabled="!result.enabled"
          @click.native.prevent
        />
      </div>

      <div :class="$style.sliderContainer">
        <div :class="[$style.label, $style.quality]">
          <div>
            Качество картинки
            {{ result.quality }} %
          </div>
        </div>
        <vue-slider
          v-model="result.quality"
          :class="$style.slider"
          :disabled="!result.enabled"
          @change="change('quality')"
        />
      </div>

      <div :class="$style.settContainer">
        <label :class="$style.label"> Масштаб </label>
        <div :class="$style.inline">
          <input
            v-model.number="result.scale"
            type="number"
            :class="$style.input"
            :disabled="!result.enabled"
            max="100"
            @change="change('scale')"
          >
          <div :class="$style.label">
            %
          </div>
        </div>
      </div>

      <div
        v-if="fieldType('Grayscale')"
        :class="[$style.settContainer, $style.toggle]"
        @click="check('Grayscale')"
      >
        <div :class="$style.label">
          Черно-белый режим
        </div>
        <toggle-button
          v-model="result.Grayscale"
          :value="result.Grayscale"
          :sync="true"
          :disabled="!result.enabled"
          @click.native.prevent
        />
      </div>

      <div
        v-if="fieldType('active_window')"
        :class="[$style.settContainer, $style.toggle]"
        @click="check('active_window')"
      >
        <div :class="$style.label">
          Снимать только активное окно
        </div>
        <toggle-button
          v-model="result.active_window"
          :value="result.active_window"
          :sync="true"
          :disabled="!result.enabled"
          @click.native.prevent
        />
      </div>

      <div
        v-if="fieldType('cursor')"
        :class="[$style.settContainer, $style.toggle]"
        @click="check('cursor')"
      >
        <div :class="$style.label">
          Снимать курсор
        </div>
        <toggle-button
          v-model="result.cursor"
          :value="result.cursor"
          :sync="true"
          :disabled="!result.enabled"
          @click.native.prevent
        />
      </div>

      <div
        v-if="fieldType('encrypt')"
        :class="[$style.settContainer, $style.toggle]"
        @click="check('encrypt')"
      >
        <div :class="$style.label">
          Шифровать скриншот
        </div>
        <toggle-button
          v-model="result.encrypt"
          :value="result.encrypt"
          :sync="true"
          :disabled="!result.enabled"
          @click.native.prevent
        />
      </div>
    </div>
    <div :class="$style.card">
      <div
        v-if="fieldType('idle')"
        :class="[$style.settContainer, $style.toggle, $style.content]"
        @click="check('idle')"
      >
        <div :class="[$style.label, $style.idle]">
          Приостановить снятие скриншотов, если система простаивает более
        </div>

        <div :class="$style.flex">
          <input
            v-model.number="result.idle_time"
            type="number"
            :class="$style.input"
            :disabled="!result.idle || !result.enabled"
            @change="change('idle_time')"
            @click.stop
          >
          <div :class="$style.min">
            минут
          </div>
        </div>
        <toggle-button
          v-model="result.idle"
          :value="result.idle"
          :sync="true"
          :disabled="!result.enabled"
          @click.native.prevent
        />
      </div>
    </div>

    <div :class="$style.card">
      <div
        v-if="fieldType('forprogs')"
        :class="[$style.settContainer, $style.toggle]"
        @click="check('forprogs')"
      >
        <div :class="$style.label">
          Делать скриншот только при работе в программах
        </div>
        <toggle-button
          v-model="result.forprogs"
          :value="result.forprogs"
          :sync="true"
          :disabled="!result.enabled"
          @click.native.prevent
        />
      </div>

      <div :class="$style.settContainer">
        <label :class="$style.label"> Интервал</label>
        <div :class="$style.inline">
          <input
            v-model.number="result.interval_app"
            type="number"
            :class="$style.input"
            :disabled="!result.forprogs || !result.enabled"
            @change="change('interval_app')"
          >
          <div>
            <select
              v-model="result.interval_type_app"
              :class="$style.select"
              :disabled="!result.forprogs || !result.enabled"
              @change="change('interval_type_app')"
            >
              <option
                v-for="option in options"
                :key="option.id"
                :value="option.id"
              >
                {{ option.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div :class="$style.progsOptions">
        <button
          :class="$style.button"
          :disabled="!result.forprogs || !result.enabled"
          @click="openModal('apps')"
        >
          Редактировать список программ
        </button>
      </div>
    </div>
    <apps-modal id="apps" :class="$style.modal" @close="closeModal('apps')" />
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import { getScreenshotSettings } from '@/api/methods/settings';
import 'vue-slider-component/theme/default.css';
import AppsModal from '@/views/settings/agents/agent/settings/apps-modal.vue';

export default {
  components: {
    VueSlider,
    AppsModal,
  },
  data: () => ({
    result: {},
    options: [
      { name: 'сек', id: 0 },
      { name: 'мин', id: 1 },
      { name: 'ч', id: 2 },
    ],
  }),
  computed: {
    selectedComputer() {
      return {
        type: this.$route.params.type,
        id: this.$route.params.computer,
      };
    },
  },
  created() {
    this.getSettings();
  },
  async mounted() {
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/cancel') {
          await this.getSettings();
        }
      },
    });
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/paramChanged') {
          await this.getSettings();
        }
      },
    });
  },
  methods: {
    change(field) {
      this.$store.commit('settings/addScreenshotSettings', {
        key: field,
        value: this.result[field],
      });
    },
    closeModal(id) {
      document.getElementById(id).style.display = 'none';
    },
    openModal(id) {
      document.getElementById(id).style.display = 'flex';
    },
    check(key) {
      if (key === 'enabled' || this.result.enabled) {
        this.$set(this.result, key, !this.result[key]);
        this.$store.commit('settings/addScreenshotSettings', {
          key,
          value: this.result[key],
        });
      }
    },
    fieldType(field) {
      return typeof this.result[field] !== 'undefined';
    },
    async getSettings() {
      let type = 0;
      if (this.selectedComputer.id) {
        if (this.selectedComputer.type === 'folder') type = 2;
        this.promise = await getScreenshotSettings(
          this.selectedComputer.id,
          type,
        ).then(({ data }) => {
          if (data) {
            this.result = data.result;
          }
        });
        const settings = this.$store.getters['settings/screenshotSettings'];
        if (settings) {
          Object.entries(settings).forEach(([key, value]) => {
            this.result[key] = value;
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" module>
.settContainer {
  display: flex;
  padding: 10px 0px;
  margin: 0px 10px;
  justify-content: space-between;

  align-items: center;

  &:hover {
    background: $light-gray;
  }
}

.toggle {
  cursor: pointer;
}

.sliderContainer {
  padding: 10px;
  display: inline-flex;
  width: 100%;
  height: 60px;
  align-items: center;
  &:hover {
    background: $light-gray;
  }
}

.slider {
  padding: 10px !important;
  width: 80% !important;
  z-index: 0 !important;
  height: 10px !important;
}

.label {
  margin: 0px 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.min {
  font-size: 14px;
  margin-right: 5px;
}

.input {
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
  width: 55px;
  margin-right: 5px;
}

.inline {
  display: inline-flex;
}

.select {
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
  width: 70px;
  cursor: pointer;
}

.quality {
  width: 60%;
}

.hr {
  width: 70%;
}

.idle {
  width: 300px;
  margin: 20px 10px;
  line-height: 20px;
}

.flex {
  display: flex;
}

.min {
  display: flex;
  align-items: center;
}
.column {
  display: flex;
  flex-direction: column;
}

.modal {
  display: none;
  z-index: 3;
}

.button {
  margin: 15px;
  height: 30px;
  cursor: pointer;
  width: 240px;
  background: #2985bf;
  border: 2px solid #2985bf;
  border-radius: 2px;
  font: inherit;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  &:disabled {
    background: $branchColor;
    border: $branchColor;
  }
}

.progsOptions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background: $light-gray;
  }
}

.progsInterval {
  padding: 5px;
  display: flex;
}

.card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 5px 0px 15px 22px;
  width: 92%;
  padding: 5px;
  > :not(:last-child) {
    border-bottom: 0.2px solid #eee;
  }
}

.disabled,
.disabled * {
  cursor: default;
}
</style>
