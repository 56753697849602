<template>
  <modal
    header-text="Список программ"
    button-text="Сохранить"
    :not-active="areEqual"
    class="apps"
    @close="$emit('close')"
    @action="checkApp()"
  >
    <scrollable-container :class="$style.scrollableContainer">
      <table :class="$style.table">
        <thead>
          <tr :class="$style.header">
            <td
              v-for="column in columns"
              :key="column.title"
              :style="{ width: column.width }"
              @click="changeSort(column)"
            >
              <div :class="$style.tdInline">
                {{ column.title }}
              </div>
              <img
                src="@/assets/images/icons/table/sort-arrow.svg"
                :class="[
                  $style.tdInline,
                  $style.arrow,
                  {
                    [$style.asc]: column.sort == 2,
                    [$style.hidden]: column.sort == 0,
                  },
                ]"
              >
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(app, index) in sortedApps"
            :key="index"
            :class="$style.row"
            @click="check(app)"
          >
            <td :class="$style.customCheckBoxContainer">
              <input
                :id="app.appId"
                v-model="checkedApps"
                type="checkbox"
                :value="app"
                :class="$style.customCheckbox"
              >
              <label><check-mark /></label>
            </td>
            <td
              v-for="column in columns.slice(1)"
              :key="index + column.title"
              :style="{ width: column.width }"
            >
              {{ app[column.field] }}
            </td>
          </tr>
        </tbody>
      </table>
    </scrollable-container>
  </modal>
</template>

<script>
import Modal from '@/components/common/modal/settings-modal.vue';
import { getScreenshotApps, changeCheckedApps } from '@/api/methods/settings';
import CheckMark from '@/components/common/filter/check-mark.vue';
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';

export default {
  components: { Modal, CheckMark, ScrollableContainer },
  data: () => ({
    apps: [],
    checkedApps: [],
    initial: [],
    columns: [
      { title: '', field: 'id', width: '10%', sort: 0 },
      { title: 'Имя файла', field: 'exeName', width: '30%', sort: 2 },
      { title: 'Заголовок окна', field: 'caption', width: '30%', sort: 0 },
      { title: 'Комментарий', field: 'comment', width: '30%', sort: 0 },
    ],
  }),
  computed: {
    sortedApps() {
      const calc = this.apps.slice(0).sort(this.compare);
      return calc;
    },
    areEqual() {
      const array1 = this.initial;
      const array2 = this.checkedApps;
      if (array1.length === array2.length) {
        return array1.every((element) => {
          if (array2.includes(element)) {
            return true;
          }

          return false;
        });
      }

      return false;
    },
    selectedComputer() {
      return {
        type: this.$route.params.type,
        id: this.$route.params.computer,
      };
    },
  },
  async created() {
    await this.getAppsList();
  },
  methods: {
    compare(a, b) {
      const index = this.columns.findIndex((x) => x.sort > 0);
      const key = this.columns[index].field;
      let a1 = a;
      let b1 = b;

      if (key !== 'id') {
        a1 = a1[key].toLowerCase();
        b1 = b1[key].toLowerCase();
      } else {
        a1 = a[key];
        b1 = b[key];
      }

      if (this.columns[index].sort === 1) {
        if (a1 > b1) {
          return -1;
        }

        if (a1 < b1) {
          return 1;
        }
        return 0;
      }

      if (this.columns[index].sort === 2) {
        if (a1 < b1) {
          return -1;
        }

        if (a1 > b1) {
          return 1;
        }

        return 0;
      }
      return 0;
    },
    containsObject(obj, list) {
      let i;
      for (i = 0; i < list.length; i++) {
        if (list[i] === obj) {
          return true;
        }
      }

      return false;
    },
    changeSort(column) {
      const { sort } = column;
      this.columns.forEach((col) => {
        col.sort = 0;
      });
      if (sort === 1) {
        column.sort = 2;
      } else column.sort = 1;
    },
    check(app) {
      const index = this.checkedApps.indexOf(app);
      if (index === -1) this.checkedApps.push(app);
      else this.checkedApps.splice(index, 1);
    },
    close() {
      this.$emit('close');
    },
    async getAppsList() {
      const apps = await getScreenshotApps(
        this.selectedComputer.id,
        this.selectedComputer.type,
      );
      this.apps = apps.data.result;
      this.apps.forEach((app) => {
        if (app.id > 0) {
          this.checkedApps.push(app);
          this.initial.push(app);
        }
      });
    },
    async checkApp() {
      if (!this.areEqual) {
        const unchecked = [];
        for (let i = 0; i < this.initial.length; i++) {
          if (!this.containsObject(this.initial[i], this.checkedApps)) {
            unchecked.push(this.initial[i]);
          }
        }

        await changeCheckedApps(
          this.checkedApps,
          unchecked,
          this.selectedComputer.id,
          this.selectedComputer.type,
        );

        this.initial = [];
        this.checkedApps = [];
        await this.getAppsList();
      }
    },
  },
};
</script>

<style lang="scss" module>
.scrollableContainer {
  max-height: 300px;
  margin: 0px 15px 15px 15px;
}
.table {
  width: 100%;
}

.header {
  position: sticky;
  z-index: 5;
  top: 0;
  background: white;
  height: 20px;
  font-weight: 500;
  cursor: pointer;
}

.customCheckBoxContainer {
  .customCheckbox {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    cursor: pointer;
  }
  input:checked + label > svg {
    animation: draw-checkbox ease-in-out 0.2s forwards;
  }
  label:active::after {
    background-color: #fbfbfb;
  }
  label {
    color: black;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    cursor: pointer;
    position: relative;
    display: block;
    &:after {
      content: '';
      height: 14px;
      width: 14px;
      float: left;
      border: 1px solid #9f9f9f;
      border-radius: 4px;
      transition: 0.15s all ease-out;
      background-color: white;
    }
  }
  input:checked + label:after {
    background-color: $blue;
    border: 1px solid $blue;
  }
  svg {
    position: absolute;
    left: 4px;
    top: 4px;
    stroke-dasharray: 33;
  }
  @keyframes draw-checkbox {
    0% {
      stroke-dashoffset: 33;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
}

.row {
  cursor: pointer;
  height: 22px;
  &:hover {
    background: $light-gray;
  }
}

.tdInline {
  display: inline-flex;
}

.arrow {
  margin: 7px 5px 0px 5px;

  &.asc {
    transform: rotate(180deg);
  }
  &.hidden {
    visibility: hidden;
  }
}
</style>

<style >
.apps .settings-modal_modal_3LaCC {
  min-width: 600px !important;
}
</style>
