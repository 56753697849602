<template>
  <div>
    <div :class="[$style.card]">
      <div
        v-if="fieldType('enabled', 0)"
        :class="$style.settContainer"
        @click="check('enabled', 0)"
      >
        <div :class="$style.label">
          Запоминать изменения файлов и папок
        </div>
        <toggle-button
          v-model="result.enabled"
          :value="result.enabled"
          :sync="true"
          @click.native.prevent
        />
      </div>
    </div>
    <div :class="[$style.card, $style.cardLines]">
      <div :class="$style.title">
        Какие действия отслеживать
      </div>
      <div
        v-if="fieldType('filecreate', 0)"
        :class="$style.settContainer"
        @click="check('filecreate', 0)"
      >
        <div :class="$style.label">
          Создание файлов и папок
        </div>
        <toggle-button
          v-model="result.filecreate"
          :value="result.filecreate"
          :sync="true"
          :disabled="!result.enabled"
          @click.native.prevent
        />
      </div>
      <div
        v-if="fieldType('filedelete', 0)"
        :class="$style.settContainer"
        @click="check('filedelete', 0)"
      >
        <div :class="$style.label">
          Удаление файлов и папок
        </div>
        <toggle-button
          v-model="result.filedelete"
          :value="result.filedelete"
          :sync="true"
          :disabled="!result.enabled"
          @click.native.prevent
        />
      </div>
      <div
        v-if="fieldType('filerename', 0)"
        :class="$style.settContainer"
        @click="check('filerename', 0)"
      >
        <div :class="$style.label">
          Переименование файлов и папок
        </div>
        <toggle-button
          v-model="result.filerename"
          :value="result.filerename"
          :sync="true"
          :disabled="!result.enabled"
          @click.native.prevent
        />
      </div>
    </div>
    <div :class="[$style.card]">
      <div v-if="fieldType('filesystem', 0)" :class="$style.switcher">
        <button
          :disabled="!result.enabled"
          :class="[$style.button, { [$style.activeButton]: result.filesystem }]"
          @click="check('filesystem', 0)"
        >
          Следить за всей файловой системой
        </button>
        <button
          :disabled="!result.enabled"
          :class="[
            $style.button,
            { [$style.activeButton]: !result.filesystem },
          ]"
          @click="check('filesystem', 0)"
        >
          Следить за отдельными каталогами
        </button>
      </div>
      <vue-tags-input
        v-model="tag"
        :tags="tags"
        :allow-edit-tags="true"
        :disabled="!result.enabled || result.filesystem"
        placeholder="Добавить путь"
        :class="$style.tags"
        @tags-changed="(newTags) => (tags = newTags)"
        @before-adding-tag="addPath"
        @before-deleting-tag="deletePath"
        @before-saving-tag="editPath"
      />
    </div>
    <div :class="[$style.card]">
      <div
        v-if="fieldType('exclusion', 0)"
        :class="$style.settContainer"
        @click="check('exclusion', 0)"
      >
        <div :class="$style.label">
          Исключать из слежения следующие пути:
        </div>
        <toggle-button
          v-model="result.exclusion"
          :value="result.exclusion"
          :sync="true"
          :disabled="!result.enabled"
          @click.native.prevent
        />
      </div>
      <vue-tags-input
        v-model="excludeTag"
        :tags="excludeTags"
        :allow-edit-tags="true"
        :disabled="!result.enabled || !result.exclusion"
        placeholder="Добавьте путь"
        :class="$style.tags"
        @tags-changed="(newExclTags) => (excludeTags = newExclTags)"
        @before-adding-tag="addExclude"
        @before-deleting-tag="deletePath"
        @before-saving-tag="editPath"
      />
    </div>
    <div v-if="indexSett" :class="[$style.card, $style.cardLines]">
      <div :class="$style.title">
        Настройки индексации документов
      </div>
      <div
        v-if="fieldType('FilesIndexing', 1)"
        :class="$style.settContainer"
        @click="check('FilesIndexing', 1)"
      >
        <div :class="$style.label">
          Вести индексацию документов на ПК
        </div>
        <toggle-button
          v-model="indexSett.FilesIndexing"
          :value="indexSett.FilesIndexing"
          :sync="true"
          @click.native.prevent
        />
      </div>
      <div :class="$style.settContainer">
        <div :class="$style.label">
          Расширения контролируемых файлов
        </div>
        <vue-tags-input
          v-model="extension"
          :tags="extensions"
          :allow-edit-tags="false"
          placeholder="Добавить расширение"
          :class="$style.tags"
          :disabled="!indexSett.FilesIndexing"
          @tags-changed="(newExt) => (extensions = newExt)"
          @before-adding-tag="addExtension"
          @before-deleting-tag="deleteExtension"
        />
      </div>
      <div :class="$style.settContainer">
        <label :class="$style.label">
          Максимальный размер индексируемого документа
        </label>
        <div :class="$style.inline">
          <input
            v-model.number="indexSett.MaxHIndexFileSize"
            type="number"
            :class="$style.input"
            :disabled="!indexSett.FilesIndexing"
            @change="change('MaxHIndexFileSize', 1)"
          >
          МБ
        </div>
      </div>
      <div
        v-if="fieldType('CreateFilesHsh', 1)"
        :class="$style.settContainer"
        @click="check('CreateFilesHsh', 1)"
      >
        <div :class="$style.label">
          Записывать хэш документов
        </div>
        <toggle-button
          v-model="indexSett.CreateFilesHsh"
          :value="indexSett.CreateFilesHsh"
          :sync="true"
          :disabled="!indexSett.FilesIndexing"
          @click.native.prevent
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button';
import Vue from 'vue';
import VueTagsInput from '@johmun/vue-tags-input';
import {
  getFileSettings,
  editAcselParams,
  delAcselParams,
  addAcselParams,
} from '@/api/methods/settings';

Vue.component('ToggleButton', ToggleButton);

export default {
  components: {
    VueTagsInput,
  },
  data: () => ({
    result: {},
    indexSett: {},
    pathList: [],
    tag: '',
    tags: [],
    excludeTag: '',
    excludeTags: [],
    extension: '',
    extensions: [],
  }),
  computed: {
    selectedComputer() {
      return {
        type: this.$route.params.type,
        id: this.$route.params.computer,
      };
    },
  },
  async created() {
    await this.getSettings();
  },
  async mounted() {
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/cancel') {
          await this.getSettings();
        }
      },
    });
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/paramChanged') {
          await this.getSettings();
        }
      },
    });
  },
  methods: {
    addExtension(object) {
      if (!this.extensions.find((tag) => tag.text === object.tag.text.trim())) {
        let res = '';
        this.extensions.forEach((item) => {
          res = `${res + item.text};`;
        });
        res += object.tag.text.trim();
        object.addTag();
        this.$store.commit('settings/addIndexSettings', {
          key: 'DocumentsExt',
          value: res,
        });
      }
    },
    deleteExtension(object) {
      object.deleteTag();
      let res = '';
      this.extensions.forEach((item) => {
        res = `${res + item.text};`;
      });
      this.$store.commit('settings/addIndexSettings', {
        key: 'DocumentsExt',
        value: res.slice(0, -1),
      });
    },
    async deletePath(object) {
      if (
        this.result.enabled
        && ((object.tag.val === 0 && this.result.exclusion)
          || (object.tag.val === 1 && !this.result.filesystem))
      ) {
        await delAcselParams(object.tag.id);
        object.deleteTag();
      }
    },
    async editPath(object) {
      if (
        this.result.enabled
        && ((object.tag.val === 0
          && this.result.exclusion
          && !this.excludeTags.find(
            (tag) => tag.text === object.tag.text.trim(),
          ))
          || (object.tag.val === 1
            && !this.result.filesystem
            && !this.tags.find((tag) => tag.text === object.tag.text.trim())))
      ) {
        await editAcselParams(
          object.tag.id,
          object.tag.val,
          object.tag.text.trim(),
        );
        object.saveTag();
      }
    },
    async addPath(object) {
      if (this.result.enabled && !this.result.filesystem) {
        if (!this.tags.find((tag) => tag.text === object.tag.text.trim())) {
          await addAcselParams(
            this.$store.getters['settings/profile'],
            1,
            object.tag.text.trim(),
          );
          object.addTag();
          await this.getSettings();
        }
      }
    },
    async addExclude(object) {
      if (this.result.enabled && this.result.exclusion) {
        if (
          !this.excludeTags.find((tag) => tag.text === object.tag.text.trim())
        ) {
          await addAcselParams(
            this.$store.getters['settings/profile'],
            0,
            object.tag.text.trim(),
          );
          object.addTag();
          await this.getSettings();
        }
      }
    },
    getTags: (users) => users.map(({ sval, id, val }) => ({ text: sval, id, val })),
    getExtTags: (ext) => ext.map((string) => ({ text: string })),
    change(field, type) {
      if (type === 0) {
        this.$store.commit('settings/addFileSettings', {
          key: field,
          value: this.result[field],
        });
      } else if (type === 1) {
        this.$store.commit('settings/addIndexSettings', {
          key: field,
          value: this.indexSett[field],
        });
      }
    },
    check(key, type) {
      if (type === 0) {
        if (key === 'enabled' || this.result.enabled) {
          this.$set(this.result, key, !this.result[key]);
          this.$store.commit('settings/addFileSettings', {
            key,
            value: this.result[key],
          });
        }
      } else if (type === 1) {
        if (key === 'FilesIndexing' || this.indexSett.FilesIndexing) {
          this.$set(this.indexSett, key, !this.indexSett[key]);
          this.$store.commit('settings/addIndexSettings', {
            key,
            value: this.indexSett[key],
          });
        }
      }
    },
    fieldType(field, type) {
      if (type === 1) {
        return typeof this.indexSett[field] !== 'undefined';
      }
      return typeof this.result[field] !== 'undefined';
    },
    async getSettings() {
      this.result = {};
      this.indexSett = {};
      let type = 0;
      if (this.selectedComputer.id) {
        if (this.selectedComputer.type === 'folder') type = 2;

        this.promise = await getFileSettings(
          this.selectedComputer.id,
          type,
        ).then(({ data }) => {
          if (data) {
            this.result = data.result.result;
            this.pathList = data.result.pathList;
            this.indexSett = data.result.indexSett;
          }
        });
        const settings = this.$store.getters['settings/fileSettings'];
        if (settings) {
          Object.entries(settings).forEach(([key, value]) => {
            this.result[key] = value;
          });
        }
        if (this.indexSett) {
          this.indexSett.MaxHIndexFileSize /= 1048576;
          const indSettings = this.$store.getters['settings/indexSettings'];
          if (indSettings) {
            Object.entries(indSettings).forEach(([key, value]) => {
              this.indexSett[key] = value;
            });
          }
          const extTags = this.indexSett.DocumentsExt.split(';');

          if (extTags[extTags.length - 1] === '') extTags.pop();
          this.extensions = this.getExtTags(extTags);
        }
        const tags = [];
        const exclude = [];
        this.pathList.forEach((path) => {
          if (path.val === 1) tags.push(path);
          if (path.val === 0) exclude.push(path);
        });
        this.tags = this.getTags(tags);
        this.excludeTags = this.getTags(exclude);
      }
    },
  },
};
</script>

<style lang="scss" module>
.settContainer {
  display: flex;
  padding: 10px 0px;
  margin: 0px 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: $light-gray;
  }
}

.label {
  margin: 0px 10px;
  font-size: 14px;
}

.input {
  margin-right: 5px;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
  width: 90px;
}

.card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 5px 0px 15px 22px;
  width: 92%;
  padding: 5px;
}
.cardLines {
  > :not(:last-child) {
    border-bottom: 0.2px solid #eee;
  }
}

.disabled,
.disabled * {
  cursor: default;
}

.inline {
  display: inline-flex;
  align-items: center;
}

.select {
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
  width: 230px;
  cursor: pointer;
}

.title {
  padding: 20px 20px 10px 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  border: 0px !important;
}

.button {
  height: 50px;
  cursor: pointer;
  width: 170px;
  background: #bfcbd9;
  border: 0px;
  font: inherit;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  &:hover {
    background: $dark-gray;
    transition: background-color 0.5s linear;
  }
  &:disabled {
    background: $branchColor;
  }
}
.activeButton {
  background: #69b382;
  &:hover {
    background: #5d9f74;
    transition: background-color 0.5s linear;
  }
  &:disabled {
    background: $branchColor;
  }
}

.switcher {
  margin: 20px;
}

.tags {
  margin: 20px;
}
</style>

<style lang="css">
div .ti-tag[data-v-61d92e31] {
  background-color: #e3f2fd;
  color: #039be5;
  font-size: 12px;
  border: 1px solid transparent;
  border-radius: 2px;
}
.ti-disabled .ti-tag[data-v-61d92e31] {
  background-color: #f5f5f5;
  color: #9a99a2;
  font-size: 12px;
  border: 1px solid transparent;
  border-radius: 2px;
}
div .vue-tags-input[data-v-61d92e31] {
  max-width: 100%;
}
</style>
