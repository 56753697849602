<template>
  <div :class="[$style.card]">
    <div
      v-if="fieldType('spy_on_start')"
      :class="$style.settContainer"
      @click="check('spy_on_start')"
    >
      <div :class="$style.label">
        Стартовать мониторинг при загрузке Windows
      </div>
      <toggle-button
        v-model="result.spy_on_start"
        :value="result.spy_on_start"
        :sync="true"
        @click.native.prevent
      />
    </div>

    <div
      v-if="fieldType('max_size')"
      :class="$style.settContainer"
      @click="check('max_size')"
    >
      <div :class="$style.label">
        Ограничить максимальный размер лог-файлов
      </div>
      <toggle-button
        v-model="result.max_size"
        :value="result.max_size"
        :sync="true"
        @click.native.prevent
      />
    </div>

    <div :class="$style.settContainer">
      <label :class="$style.label"> Максимальный размер текстовых логов </label>
      <div :class="$style.inline">
        <input
          v-model.number="result.max_text"
          type="number"
          :class="$style.input"
          :disabled="!result.max_size"
          @change="change('max_text')"
        >
        КБ
      </div>
    </div>
    <div :class="$style.settContainer">
      <label :class="$style.label"> Максимальный размер скриншотов </label>
      <div :class="$style.inline">
        <input
          v-model.number="result.max_scr"
          type="number"
          :class="$style.input"
          :disabled="!result.max_size"
          @change="change('max_scr')"
        >
        КБ
      </div>
    </div>

    <div
      v-if="fieldType('clear')"
      :class="$style.settContainer"
      @click="check('clear')"
    >
      <div :class="$style.label">
        Очищать лог-файлы при превышении максимального размера
      </div>
      <toggle-button
        v-model="result.clear"
        :value="result.clear"
        :sync="true"
        @click.native.prevent
      />
    </div>
  </div>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button';
import Vue from 'vue';
import { getLogSettings } from '@/api/methods/settings';

Vue.component('ToggleButton', ToggleButton);

export default {
  data: () => ({
    result: {},
  }),
  computed: {
    selectedComputer() {
      return {
        type: this.$route.params.type,
        id: this.$route.params.computer,
      };
    },
  },
  async created() {
    await this.getSettings();
  },
  async mounted() {
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/cancel') {
          await this.getSettings();
        }
      },
    });
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/paramChanged') {
          await this.getSettings();
        }
      },
    });
  },
  methods: {
    change(field) {
      this.$store.commit('settings/addLogSettings', {
        key: field,
        value: this.result[field],
      });
    },
    check(key) {
      this.$set(this.result, key, !this.result[key]);
      this.$store.commit('settings/addLogSettings', {
        key,
        value: this.result[key],
      });
    },
    fieldType(field) {
      return typeof this.result[field] !== 'undefined';
    },
    async getSettings() {
      this.result = {};
      let type = 0;
      if (this.selectedComputer.id) {
        if (this.selectedComputer.type === 'folder') type = 2;

        this.promise = await getLogSettings(
          this.selectedComputer.id,
          type,
        ).then(({ data }) => {
          if (data) {
            this.result = data.result;
          }
        });
        const settings = this.$store.getters['settings/logSettings'];
        if (settings) {
          Object.entries(settings).forEach(([key, value]) => {
            this.result[key] = value;
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" module>
.settContainer {
  display: flex;
  padding: 10px 0px;
  margin: 0px 10px;
  justify-content: space-between;
  //width: 90%;
  align-items: center;
  cursor: pointer;
  //margin-left: 10px;
  &:hover {
    background: $light-gray;
  }
}

.label {
  margin: 0px 10px;
  font-size: 14px;
}

.input {
  margin-right: 5px;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 30px;
  width: 90px;
}

.card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 5px 0px 15px 22px;
  width: 92%;
  padding: 5px;
  > :not(:last-child) {
    border-bottom: 0.2px solid #eee;
  }
}

.disabled,
.disabled * {
  cursor: default;
}

.inline {
  display: inline-flex;
  align-items: center;
}
</style>
