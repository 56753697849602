<template>
  <div>
    <component :is="component" />
  </div>
</template>

<script>
import Log from '@/views/settings/agents/agent/settings/log.vue';
import Common from '@/views/settings/agents/agent/settings/common.vue';
import Screenshots from '@/views/settings/agents/agent/settings/screenshots.vue';
import Worktime from '@/views/settings/agents/agent/settings/worktime.vue';
import ShadowCopy from '@/views/settings/agents/agent/settings/shadowCopy.vue';
import Files from '@/views/settings/agents/agent/settings/files.vue';
import Drives from '@/views/settings/agents/agent/settings/drives.vue';
import Internet from '@/views/settings/agents/agent/settings/internet.vue';
import Webcam from '@/views/settings/agents/agent/settings/webcam.vue';

export default {
  data: () => ({}),
  computed: {
    component() {
      switch (this.$route.params.log) {
        case 'log':
          return Log;
        case 'common':
          return Common;
        case 'screenshots':
          return Screenshots;
        case 'worktime':
          return Worktime;
        case 'shadowCopy':
          return ShadowCopy;
        case 'files':
          return Files;
        case 'drives':
          return Drives;
        case 'internet':
          return Internet;
        case 'webcam':
          return Webcam;
        default:
          throw Error(`Unknown default log ${this.$route.params.log}`);
      }
    },
  },
};
</script>
