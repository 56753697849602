<template>
  <div>
    <modal
      header-text="Профиль продуктивности"
      button-text="Сохранить"
      class="grModal"
      @action="save()"
      @close="$emit('close')"
    >
      <div>
        <div :class="$style.profContainer">
          <div class="agentsSelect prod">
            <vue-select
              v-model="selectedProfile"
              :options="profiles"
              label="name"
              :reduce="(option) => option.id"
              :clearable="false"
              :searchable="false"
              :class="$style.select"
              @input="checkIfChanged"
            >
              <!-- eslint-disable-next-line vue/no-unused-vars  -->
              <template #no-options="{ search, searching, loading }">
                Нет профилей для выбора
              </template>
            </vue-select>
          </div>
          <button :class="$style.button" @click="openModal('addModal')">
            Добавить
          </button>
          <button
            :class="[$style.button, $style.buttonDelete]"
            @click="openModal('delModal')"
          >
            Удалить
          </button>
        </div>
        <tabset
          class="grTabs"
          :tabs="tabs"
          :opened="true"
          :hide-arrow="true"
          :tab-style="tabStyle"
          @tabClick="openNewTab"
        />

        <div v-show="tabs[0].active" :class="$style.row">
          <div :class="[$style.block, $style.prod]">
            <h3 :class="$style.header">
              Продуктивные
            </h3>
            <draggable
              :id="1"
              group="apps"
              :list="appsProductive"
              :class="$style.list"
              @end="onMoveCallback"
            >
              <div
                v-for="element in appsProductive"
                :id="element.groupId"
                :key="element.groupId"
                class="list-group-item"
                :class="$style.item"
              >
                {{ element.groupName }}
              </div>
            </draggable>
          </div>

          <div :class="[$style.block, $style.neutral]">
            <h3 :class="$style.header">
              Нейтральные
            </h3>
            <draggable
              :id="2"
              group="apps"
              :list="appsNeutral"
              :class="$style.list"
              @end="onMoveCallback"
            >
              <div
                v-for="element in appsNeutral"
                :id="element.groupId"
                :key="element.groupId"
                class="list-group-item"
                :class="$style.item"
              >
                {{ element.groupName }}
              </div>
            </draggable>
          </div>

          <div :class="[$style.block, $style.unprod]">
            <h3 :class="$style.header">
              Непродуктивные
            </h3>
            <draggable
              :id="0"
              group="apps"
              :list="appsUnproductive"
              :class="$style.list"
              @end="onMoveCallback"
            >
              <div
                v-for="element in appsUnproductive"
                :id="element.groupId"
                :key="element.groupId"
                class="list-group-item"
                :class="$style.item"
              >
                {{ element.groupName }}
              </div>
            </draggable>
          </div>
        </div>

        <div v-show="tabs[1].active" :class="$style.row">
          <div :class="[$style.block, $style.prod]">
            <h3 :class="$style.header">
              Продуктивные
            </h3>
            <draggable
              :id="1"
              group="web"
              :list="webProductive"
              :class="$style.list"
              @end="onMoveCallback"
            >
              <div
                v-for="element in webProductive"
                :id="element.groupId"
                :key="element.groupId"
                class="list-group-item"
                :class="$style.item"
              >
                {{ element.groupName }}
              </div>
            </draggable>
          </div>

          <div :class="[$style.block, $style.neutral]">
            <h3 :class="$style.header">
              Нейтральные
            </h3>
            <draggable
              :id="2"
              group="web"
              :list="webNeutral"
              :class="$style.list"
              @end="onMoveCallback"
            >
              <div
                v-for="element in webNeutral"
                :id="element.groupId"
                :key="element.groupId"
                class="list-group-item"
                :class="$style.item"
              >
                {{ element.groupName }}
              </div>
            </draggable>
          </div>

          <div :class="[$style.block, $style.unprod]">
            <h3 :class="$style.header">
              Непродуктивные
            </h3>
            <draggable
              :id="0"
              group="web"
              :list="webUnproductive"
              :class="$style.list"
              @end="onMoveCallback"
            >
              <div
                v-for="element in webUnproductive"
                :id="element.groupId"
                :key="element.groupId"
                class="list-group-item"
                :class="$style.item"
              >
                {{ element.groupName }}
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </modal>
    <add-prof-modal
      id="addModal"
      :class="$style.addModal"
      type="prod"
      @close="closeModal('addModal')"
      @update="$emit('update')"
    />
    <del-prof-modal
      id="delModal"
      :class="$style.addModal"
      :selected="selected"
      type="prod"
      @close="closeModal('delModal')"
      @update="updateAfterDelete()"
    />
    <save-settings-modal
      id="saveChanged"
      :class="$style.addModal"
      :selected="profileName"
      @save="saveWhenChangeProfile()"
      @dontSave="changeProfile(profToChange)"
      @cancel="closeModal('saveChanged')"
    />
  </div>
</template>

<script>
import VueSelect from 'vue-select';
import draggable from 'vuedraggable';
import Modal from '@/components/common/modal/settings-modal.vue';
import Tabset from '@/components/common/tabs/single-view-tabs.vue';
import { getProdGroups, saveProdGroups } from '@/api/methods/settings';
import AddProfModal from '@/views/settings/agents/agent/settings/add-profile-modal.vue';
import DelProfModal from '@/views/settings/agents/agent/settings/del-profile-modal.vue';
import SaveSettingsModal from '@/views/settings/agents/agent/settings/save-settings-modal.vue';

export default {
  components: {
    Modal,
    Tabset,
    VueSelect,
    draggable,
    AddProfModal,
    DelProfModal,
    SaveSettingsModal,
  },
  props: ['profiles'],
  data: () => ({
    selected: 1,
    profToChange: undefined,
    tabStyle: {
      padding: '10px',
      fontSize: '14px',
    },
    tabs: [
      {
        title: 'Программы',
        active: true,
      },
      {
        title: 'Посещенные сайты',
        active: false,
      },
    ],
    appsProductive: [],
    appsUnproductive: [],
    appsNeutral: [],
    webProductive: [],
    webUnproductive: [],
    webNeutral: [],
    appGroups: [],
    webGroups: [],
    appsGroupsState: {},
    webGroupsState: {},
  }),
  computed: {
    profileName() {
      if (this.profiles.length > 0 && this.selected) {
        return this.profiles.find((i) => i.id === this.selected).name;
      }
      return '';
    },
    selectedProfile: {
      get() {
        return this.selected;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  async created() {
    await this.getGroups();
  },
  methods: {
    async updateAfterDelete() {
      this.$emit('update');
      await this.changeProfile(1);
    },
    closeModal(id) {
      document.getElementById(id).style.display = 'none';
    },
    openModal(id) {
      document.getElementById(id).style.display = 'flex';
    },
    async checkIfChanged(val) {
      const appsResult = this.getChangedGroups(
        this.appsGroupsState,
        this.appGroups,
      );
      const webResult = this.getChangedGroups(
        this.webGroupsState,
        this.webGroups,
      );
      if (
        appsResult.arrToAdd.length > 0
        || appsResult.arrToDelete.length > 0
        || webResult.arrToAdd.length > 0
        || webResult.arrToDelete.length > 0
      ) {
        this.profToChange = val;
        this.openModal('saveChanged');
      } else await this.changeProfile(val);
    },
    async changeProfile(val) {
      this.selected = val;
      await this.getGroups();
      this.closeModal('saveChanged');
    },
    getChangedGroups(groupsState, groups) {
      const arrToAdd = [];
      const arrToDelete = [];
      Object.entries(groupsState).forEach(([key, value]) => {
        if (value.initial !== value.last) {
          if (value.last === 2) {
            const group = groups.find(
              (grp) => grp.groupId === parseInt(key, 10),
            );
            const id = group.ident;
            arrToDelete.push({ groupId: key, id });
          } else {
            arrToAdd.push({ groupId: key, prodType: value.last });
          }
        }
      });
      return { arrToAdd, arrToDelete };
    },
    async save() {
      const appsResult = this.getChangedGroups(
        this.appsGroupsState,
        this.appGroups,
      );
      const webResult = this.getChangedGroups(
        this.webGroupsState,
        this.webGroups,
      );
      if (
        appsResult.arrToAdd.length > 0
        || appsResult.arrToDelete.length > 0
        || webResult.arrToAdd.length > 0
        || webResult.arrToDelete.length > 0
      ) {
        await saveProdGroups(
          appsResult.arrToAdd,
          appsResult.arrToDelete,
          webResult.arrToAdd,
          webResult.arrToDelete,
          this.selected,
        );
      }

      this.appsGroupsState = {};
      this.webGroupsState = {};
      await this.getGroups();
    },
    async saveWhenChangeProfile() {
      await this.save();
      await this.changeProfile(this.profToChange);
    },
    async getGroups() {
      const aProductive = [];
      const aUnproductive = [];
      const aNeutral = [];
      const wProductive = [];
      const wUnproductive = [];
      const wNeutral = [];
      if (this.selected) {
        const result = await getProdGroups(this.selected);
        if (result.result) {
          this.appGroups = result.result.appGroups;
          this.appGroups.forEach((group) => {
            if (group.ident > 0) {
              this.appsGroupsState[group.groupId] = {
                initial: group.prodType,
                last: group.prodType,
              };
              if (group.prodType === 1) aProductive.push(group);
              else aUnproductive.push(group);
            } else {
              aNeutral.push(group);
              this.appsGroupsState[group.groupId] = { initial: 2, last: 2 };
            }
          });
          this.webGroups = result.result.webGroups;
          this.webGroups.forEach((group) => {
            if (group.ident > 0) {
              this.webGroupsState[group.groupId] = {
                initial: group.prodType,
                last: group.prodType,
              };
              if (group.prodType === 1) wProductive.push(group);
              else wUnproductive.push(group);
            } else {
              wNeutral.push(group);
              this.webGroupsState[group.groupId] = { initial: 2, last: 2 };
            }
          });
        }
        this.appsProductive = aProductive;
        this.appsUnproductive = aUnproductive;
        this.appsNeutral = aNeutral;
        this.webProductive = wProductive;
        this.webUnproductive = wUnproductive;
        this.webNeutral = wNeutral;
      }
    },
    openNewTab(index) {
      this.tabs.forEach((item) => {
        item.active = false;
      });

      this.tabs[index].active = true;
    },
    close() {
      this.$emit('close');
    },
    onMoveCallback(evt) {
      const target = evt.to.id;
      const item = evt.item.id;
      if (this.tabs[0].active) {
        this.appsGroupsState[item].last = parseInt(target, 10);
      } else this.webGroupsState[item].last = parseInt(target, 10);
    },
  },
};
</script>

<style lang="scss" module>
.row {
  display: flex;
  margin: 10px;
}
.block {
  width: 33%;
  margin: 5px;
  border-radius: 3px;
  height: 363px;
  &.prod {
    background: #7cd890;
  }
  &.neutral {
    background: #ffecbc;
  }
  &.unprod {
    background: #ffb0b0;
  }
}
.header {
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
}
.list {
  padding: 10px;
  height: 100%;
}
.item {
  padding: 10px;
  //border: 1px solid lightgrey;
  margin: 5px;
  background: white;
  cursor: grab;
}
.select {
  margin: 10px 0px;
  //width: 60%;
}
.button {
  margin: 5px;
  height: 36px;
  cursor: pointer;
  width: 90px;
  background: #2985bf;
  border: 2px solid #2985bf;
  border-radius: 2px;
  font: inherit;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}
.buttonDelete {
  background: $red;
  border: $red;
}
.profContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.addModal {
  display: none;
  z-index: 5;
}
</style>

<style>
.grTabs .single-view-tabs_tabs_2UQRS {
  height: 40px !important;
}

.grTabs .single-view-tabs_linkContainer_3LuJg {
  height: 35px !important;
}

.grTabs a:hover {
  height: 35px !important;
}

.grModal .settings-modal_modal_3LaCC {
  min-width: 560px !important;
}

.grModal .modal_modalBody_1IJqV {
  padding: 10px 20px !important;
}

.prod {
  width: 60%;
}
</style>
