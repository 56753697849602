import * as dayjs from 'dayjs';

export function dateToString(date) {
  return dayjs(date)
    .format('YYYY-MM-DD HH:mm:ss');
}

export function dateToUtc(date) {
  date = dateToString(date);
  date = date.replace(' ', 'T');
  date += '.000Z';
  return date;
}

export function formatDate(dateString) {
  const date = new Date(dateString);
  return `${(`00${date.getDate()}`).slice(-2)}.${(
    `00${date.getMonth() + 1}`
  ).slice(-2)}.${date.getFullYear()}`;
}

export function formatDateToDayString(date) {
  let month = (date.getMonth() + 1).toString();
  if (month.length < 2) month = `0${month}`;
  let day = date.getDate().toString();
  if (day.length < 2) day = `0${day}`;
  const formattedDate = `${day}.${month}.${date.getFullYear().toString().slice(-2)}`;
  return formattedDate;
}

export function convertSecondsToDays(secNum) {
  if (secNum === 0) return '0 мин';

  let minus = false;

  if (secNum < 0) {
    minus = true;
    secNum = -secNum;
  }

  let result = '';

  const days = Math.floor(secNum / 86400);
  const hours = Math.floor((secNum - days * 86400) / 3600);
  const minutes = Math.floor((secNum - days * 86400 - hours * 3600) / 60);
  const seconds = secNum - days * 86400 - hours * 3600 - minutes * 60;

  if (days > 0) result = `${days} д. ${hours} ч. ${minutes} мин. `;
  else if (hours > 0) result = `${hours} ч. ${minutes} мин. `;
  else if (minutes > 0) result = `${minutes} мин. `;
  else if (seconds > 0) result = 'менее 1 мин';

  if (minus) result = `-${result}`;

  return result;
}

export function getTime(dateString) {
  const date = new Date(dateString);
  return `${`00${date.getHours()}`.slice(
    -2,
  )}:${`00${date.getMinutes()}`.slice(-2)}:${`00${date.getSeconds()}`.slice(
    -2,
  )}`;
}
