<template>
  <div :class="[$style.card]">
    <label
      v-for="([key, value], index) in Object.entries(result)"
      :key="index + key"
      :class="$style.settContainer"
      @click="check(key)"
      @click.stop.prevent
    >
      <div :class="$style.label">
        {{ value.description }}
      </div>
      <toggle-button
        v-model="value.value"
        :value="value.value"
        :sync="true"
        @click.native.prevent
      />
    </label>
  </div>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button';
import Vue from 'vue';
import { getCommonSettings } from '@/api/methods/settings';

Vue.component('ToggleButton', ToggleButton);

export default {
  data: () => ({
    result: {},
  }),
  computed: {
    selectedComputer() {
      return {
        type: this.$route.params.type,
        id: this.$route.params.computer,
      };
    },
  },
  created() {
    this.getSettings();
  },
  async mounted() {
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/cancel') {
          await this.getSettings();
        }
      },
    });
    this.$store.subscribeAction({
      // eslint-disable-next-line
      after: async (action, state) => {
        if (action.type === 'settings/paramChanged') {
          await this.getSettings();
        }
      },
    });
  },
  methods: {
    check(key) {
      this.$set(this.result[key], 'value', !this.result[key].value);
      this.$store.commit('settings/addCommonSettings', {
        key,
        value: this.result[key],
      });
    },
    async getSettings() {
      let type = 0;
      if (this.selectedComputer.id) {
        if (this.selectedComputer.type === 'folder') type = 2;
        this.promise = await getCommonSettings(
          this.selectedComputer.id,
          type,
        ).then(({ data }) => {
          if (data) {
            this.result = data.result;
          }
        });
        const settings = this.$store.getters['settings/commonSettings'];
        if (settings) {
          Object.entries(settings).forEach(([key, value]) => {
            this.result[key].value = value.value;
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" module>
.settContainer {
  display: flex;
  padding: 10px 0px;
  margin: 0px 10px;
  justify-content: space-between;
  // width: 90%;
  align-items: center;
  cursor: pointer;
  // margin-left: 10px;
  &:hover {
    background: $light-gray;
  }
}

.label {
  margin: 0px 10px;
  font-size: 14px;
}

.card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 5px 0px 15px 22px;
  width: 92%;
  padding: 5px;
  > :not(:last-child) {
    border-bottom: 0.2px solid #eee;
  }
}

.disabled,
.disabled * {
  cursor: default;
}
</style>
