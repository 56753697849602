<template>
  <modal
    header-text=""
    button-text="Сохранить"
    @action="$emit('save')"
    @close="$emit('cancel')"
  >
    <div :class="$style.inputContainer">
      Сохранить изменения для профиля {{ selected }}?
    </div>

    <template v-slot:footer>
      <button :class="$style.button" @click="$emit('dontSave')">
        Не сохранять
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/common/modal/settings-modal.vue';

export default {
  components: { Modal },
  props: ['selected'],
};
</script>

<style lang="scss" module>
.inputContainer {
  font-size: 13px;
  margin: 5px;
}
.button {
  height: 30px;
  cursor: pointer;
  margin: 0px 5px;
  background: #2985bf;
  border: 2px solid #2985bf;
  border-radius: 2px;
  font: inherit;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  width: 105px;
}
</style>
