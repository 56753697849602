<template>
  <modal
    header-text="Список разрешенных USB устройств"
    :hide-footer="true"
    :hide-cross="false"
    class="usb"
    @close="$emit('close')"
  >
    <div v-if="loading" :class="$style.loaderContainer">
      <loader :class="$style.loader" />
      <div :class="$style.loaderText">
        Добавление...
      </div>
    </div>
    <div v-else :class="$style.colContainer">
      <div :class="$style.tableContainer">
        <div :class="$style.title">
          Список разрешенных серийников
        </div>
        <search name="whiteListFilter" :class="$style.search" />
        <div :class="$style.checkAll">
          <div :class="$style.checkAllContainer" @click="checkAll(1)">
            <div :class="$style.customCheckBoxContainer">
              <input
                id="checkAllWL"
                v-model="isAllWhiteListChecked"
                type="checkbox"
                :value="isAllWhiteListChecked"
                :class="$style.customCheckbox"
              >
              <label><check-mark /></label>
            </div>
            <div :class="$style.checkAllLabel">
              Выбрать все
            </div>
          </div>
          <button
            :class="[$style.button, $style.delete]"
            @click="deleteSerial(1)"
          >
            Удалить серийники
          </button>
        </div>
        <scrollable-container :class="$style.scrollableContainerWL">
          <table :class="$style.table">
            <thead>
              <tr :class="$style.header">
                <td
                  v-for="column in columns"
                  :key="column.title"
                  :style="{ width: column.width }"
                  @click="changeSort(column, 1)"
                >
                  <div :class="$style.tdInline">
                    {{ column.title }}
                  </div>
                  <img
                    src="@/assets/images/icons/table/sort-arrow.svg"
                    :class="[
                      $style.tdInline,
                      $style.arrow,
                      {
                        [$style.asc]: column.sortWL == 2,
                        [$style.hidden]: column.sortWL == 0,
                      },
                    ]"
                  >
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(serial, index) in sortedWhiteList"
                :key="index"
                :class="$style.row"
                @click="check(serial, 1)"
              >
                <td :class="$style.customCheckBoxContainer">
                  <input
                    :id="serial.hsId"
                    v-model="checkedWL"
                    type="checkbox"
                    :value="serial"
                    :class="$style.customCheckbox"
                  >
                  <label><check-mark /></label>
                </td>
                <td :style="{ width: columns[1].width }">
                  {{ serial[columns[1].field] }}
                </td>
                <td :style="{ width: columns[2].width }">
                  <input
                    v-model="serial[columns[2].field]"
                    type="text"
                    :class="$style.comment"
                    @focusout="editComment(serial)"
                    @click.stop.prevent
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </scrollable-container>
      </div>
      <div :class="$style.buttonsContainer">
        <button
          v-if="selectedComputer.type === 'computer'"
          :class="[$style.button, $style.green]"
          @click="addToWhiteList()"
        >
          <img
            src="@/assets/images/icons/navigation/white-arrow.svg"
            :class="$style.svg"
          >
          <div :class="$style.moveLeft">
            В список
          </div>
        </button>
        <button
          v-if="selectedComputer.type === 'computer'"
          :class="[$style.button, $style.red]"
          @click="delFromWL()"
        >
          <div :class="$style.moveRight">
            Убрать из списка
          </div>
          <img
            src="@/assets/images/icons/navigation/white-arrow.svg"
            :class="$style.svgRight"
          >
        </button>
        <button
          :class="[$style.button, $style.green]"
          @click="addToWhiteListForProf()"
        >
          <img
            src="@/assets/images/icons/navigation/white-arrow.svg"
            :class="$style.svg"
          >
          <div :class="$style.moveLeft">
            В список для всех ПК профиля
          </div>
        </button>
        <button
          :class="[$style.button, $style.green]"
          @click="addToWhiteListForAll()"
        >
          <img
            src="@/assets/images/icons/navigation/white-arrow.svg"
            :class="$style.svg"
          >
          <div :class="$style.moveLeft">
            В список для всех ПК
          </div>
        </button>
        <button :class="[$style.button, $style.red]" @click="delFromWLForAll()">
          <div :class="$style.moveRight">
            Убрать из списка для всех ПК
          </div>
          <img
            src="@/assets/images/icons/navigation/white-arrow.svg"
            :class="$style.svgRight"
          >
        </button>
      </div>
      <div :class="$style.tableContainer">
        <div>
          <label for="file-upload" class="custom-file-upload">
            Импортировать из txt файла
          </label>
          <input id="file-upload" ref="doc" type="file" @change="readFile()">
        </div>
        <div :class="$style.title">
          Список нераспределенных USB устройств ({{ availableNumber }})
        </div>
        <search :class="$style.search" />
        <div :class="$style.checkAll">
          <div :class="$style.checkAllContainer" @click="checkAll(0)">
            <div :class="$style.customCheckBoxContainer">
              <input
                id="checkAllAvailable"
                v-model="isAllAvailableChecked"
                type="checkbox"
                :value="isAllAvailableChecked"
                :class="$style.customCheckbox"
              >
              <label><check-mark /></label>
            </div>
            <div :class="$style.checkAllLabel">
              Выбрать все
            </div>
          </div>
          <button
            :class="[$style.button, $style.delete]"
            @click="deleteSerial(0)"
          >
            Удалить серийники
          </button>
        </div>
        <scrollable-container :class="$style.scrollableContainer">
          <table :class="$style.table">
            <thead>
              <tr :class="$style.header">
                <td
                  v-for="column in columns"
                  :key="column.title"
                  :style="{ width: column.width }"
                  @click="changeSort(column, 0)"
                >
                  <div :class="$style.tdInline">
                    {{ column.title }}
                  </div>
                  <img
                    src="@/assets/images/icons/table/sort-arrow.svg"
                    :class="[
                      $style.tdInline,
                      $style.arrow,
                      {
                        [$style.asc]: column.sort == 2,
                        [$style.hidden]: column.sort == 0,
                      },
                    ]"
                  >
                </td>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(serial, index) in sortedAvailable"
                :key="index"
                :class="$style.row"
                @click="check(serial, 0)"
              >
                <td :class="$style.customCheckBoxContainer">
                  <input
                    :id="serial.hsId"
                    v-model="checkedAvailable"
                    type="checkbox"
                    :value="serial"
                    :class="$style.customCheckbox"
                  >
                  <label><check-mark /></label>
                </td>
                <td :style="{ width: columns[1].width }">
                  {{ serial[columns[1].field] }}
                </td>
                <td :style="{ width: columns[2].width }">
                  <input
                    v-model="serial[columns[2].field]"
                    type="text"
                    :class="$style.comment"
                    @focusout="editComment(serial)"
                    @click.stop.prevent
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </scrollable-container>
      </div>
    </div>
  </modal>
</template>

<script>
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import Modal from '@/components/common/modal/settings-modal.vue';
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';
import {
  getSerials,
  setSerial,
  setSerialForProfile,
  setSerialForAll,
  removeSerial,
  removeSerialForAll,
  delSerial,
  editComment,
  addSerial,
} from '@/api/methods/settings';
import CheckMark from '@/components/common/filter/check-mark.vue';
import Search from '@/components/pages/computers/log/screenshots/components-header/search.vue';
import 'vue-toast-notification/dist/theme-default.css';
import Loader from '@/components/common/blocks/loader.vue';

Vue.use(VueToast);

export default {
  components: {
    Modal,
    ScrollableContainer,
    CheckMark,
    Search,
    Loader,
  },
  data: () => ({
    columns: [
      { title: '', field: 'hsId', width: '10%', sort: 0, sortWL: 0 },
      {
        title: 'Серийный номер',
        field: 'hardSerial',
        width: '45%',
        sort: 2,
        sortWL: 2,
      },
      {
        title: 'Комментарий',
        field: 'comment',
        width: '45%',
        sort: 0,
        sortWL: 0,
      },
    ],
    whiteList: [],
    serials: [],
    checkedWL: [],
    checkedAvailable: [],
    available: [],
    file: null,
    content: null,
    newSerials: [],
    loading: false,
  }),
  computed: {
    isAllWhiteListChecked() {
      if (this.sortedWhiteList.length > 0) {
        if (this.$route.query.whiteListFilter) {
          let result = true;
          this.sortedWhiteList.forEach((item) => {
            if (!this.checkedWL.some((y) => y.hsId === item.hsId)) {
              result = false;
            }
          });
          return result;
        }
        return this.whiteList.length === this.checkedWL.length;
      }
      return false;
    },
    isAllAvailableChecked() {
      if (this.sortedAvailable.length > 0) {
        if (this.$route.query.filter) {
          let result = true;
          this.sortedAvailable.forEach((item) => {
            if (!this.checkedAvailable.some((y) => y.hsId === item.hsId)) {
              result = false;
            }
          });
          return result;
        }
        return this.available.length === this.checkedAvailable.length;
      }
      return false;
    },
    availableNumber() {
      return this.available.length;
    },
    sortedWhiteList() {
      let search = this.$route.query.whiteListFilter;
      if (search) search = search.toLowerCase();
      else search = '';
      const filtered = this.whiteList
        .slice(0)
        .filter((item) => item.hardSerial.toLowerCase().includes(search));
      const calc = filtered.slice(0).sort(this.compareWL);
      return calc;
    },
    sortedAvailable() {
      let search = this.$route.query.filter;
      if (search) search = search.toLowerCase();
      else search = '';
      const filtered = this.available
        .slice(0)
        .filter((item) => item.hardSerial.toLowerCase().includes(search));
      const calc = filtered.slice(0).sort(this.compare);
      return calc;
    },
    selectedComputer() {
      return {
        type: this.$route.params.type,
        id: this.$route.params.computer,
      };
    },
  },
  async created() {
    await this.getSerials();
  },
  methods: {
    async editComment(serial) {
      await editComment(serial.hsId, serial.comment);
    },
    async readFile() {
      this.file = this.$refs.doc.files[0];
      const reader = new FileReader();
      if (this.file.name.includes('.txt')) {
        reader.onload = async (res) => {
          this.content = res.target.result;
          if (this.content.length > 0) {
            await addSerial(this.content.split('\r\n'));
            await this.getSerials();
          } else {
            Vue.$toast.open({
              message: 'Файл пуст',
              type: 'error',
            });
          }
        };
        reader.readAsText(this.file);
      } else {
        Vue.$toast.open({
          message: 'Выберите файл формата .txt',
          type: 'error',
        });
      }
    },
    containsObject(obj, list) {
      let i;
      for (i = 0; i < list.length; i++) {
        if (list[i] === obj) {
          return true;
        }
      }

      return false;
    },
    async deleteSerial(type) {
      if (type === 0) {
        if (this.checkedAvailable.length > 0) {
          await delSerial(this.checkedAvailable);
          await this.getSerials();
        }
      } else if (type === 1) {
        if (this.checkedWL.length > 0) {
          await delSerial(this.checkedWL);
          await this.getSerials();
        }
      }
    },
    checkAll(type) {
      if (type === 0) {
        if (this.$route.query.filter) {
          if (this.isAllAvailableChecked) {
            this.sortedAvailable.forEach((item) => {
              const index = this.checkedAvailable.indexOf(item);
              if (index !== -1) this.checkedAvailable.splice(index, 1);
            });
          } else {
            this.sortedAvailable.forEach((item) => {
              if (!this.containsObject(this.checkedAvailable, item)) {
                this.checkedAvailable.push(item);
              }
            });
          }
        } else if (this.isAllAvailableChecked) this.checkedAvailable = [];
        else this.checkedAvailable = this.available.slice(0);
      } else if (type === 1) {
        if (this.$route.query.whiteListFilter) {
          if (this.isAllWhiteListChecked) {
            this.sortedWhiteList.forEach((item) => {
              const index = this.checkedWL.indexOf(item);
              if (index !== -1) this.checkedWL.splice(index, 1);
            });
          } else {
            this.sortedWhiteList.forEach((item) => {
              if (!this.containsObject(this.checkedWL, item)) {
                this.checkedWL.push(item);
              }
            });
          }
        } else if (this.isAllWhiteListChecked) this.checkedWL = [];
        else this.checkedWL = this.whiteList.slice(0);
      }
    },
    async addToWhiteList() {
      if (this.checkedAvailable.length > 0) {
        await setSerial(this.selectedComputer.id, this.checkedAvailable);
        await this.getSerials();
      }
    },
    async addToWhiteListForProf() {
      if (this.checkedAvailable.length > 0) {
        this.loading = true;
        await setSerialForProfile(
          this.$store.getters['settings/profile'],
          this.checkedAvailable,
        );
        await this.getSerials();
        this.loading = false;
      }
    },
    async addToWhiteListForAll() {
      if (this.checkedAvailable.length > 0) {
        this.loading = true;
        await setSerialForAll(this.checkedAvailable);
        await this.getSerials();
        this.loading = false;
      }
    },
    async delFromWL() {
      if (this.checkedWL.length > 0) {
        await removeSerial(this.checkedWL);
        await this.getSerials();
      }
    },
    async delFromWLForAll() {
      if (this.checkedWL.length > 0) {
        await removeSerialForAll(this.checkedWL);
        await this.getSerials();
      }
    },
    async getSerials() {
      this.whiteList = [];
      this.available = [];
      this.checkedWL = [];
      this.checkedAvailable = [];
      let { id } = this.selectedComputer;
      if (this.selectedComputer.type === 'folder') id = 999999;
      const serials = await getSerials(id);
      this.serials = serials.data.result;
      this.serials.forEach((ser) => {
        if (ser.id > 0) this.whiteList.push(ser);
        else this.available.push(ser);
      });
    },
    check(serial, type) {
      let list = [];
      if (type === 0) list = this.checkedAvailable;
      if (type === 1) list = this.checkedWL;
      const index = list.indexOf(serial);
      if (index === -1) list.push(serial);
      else list.splice(index, 1);
    },
    comp(a, b, name) {
      const index = this.columns.findIndex((x) => x[name] > 0);
      const key = this.columns[index].field;
      let a1 = a;
      let b1 = b;

      if (key !== 'hsId') {
        a1 = a1[key].toLowerCase();
        b1 = b1[key].toLowerCase();
      } else {
        a1 = a[key];
        b1 = b[key];
      }

      if (this.columns[index][name] === 1) {
        if (a1 > b1) {
          return -1;
        }

        if (a1 < b1) {
          return 1;
        }
        return 0;
      }

      if (this.columns[index][name] === 2) {
        if (a1 < b1) {
          return -1;
        }

        if (a1 > b1) {
          return 1;
        }

        return 0;
      }
      return 0;
    },
    compareWL(a, b) {
      return this.comp(a, b, 'sortWL');
    },
    compare(a, b) {
      return this.comp(a, b, 'sort');
    },
    changeSort(column, type) {
      let name = 'sort';
      if (type === 1) name = 'sortWL';
      const sort = column[name];
      this.columns.forEach((col) => {
        col[name] = 0;
      });
      if (sort === 1) {
        column[name] = 2;
      } else column[name] = 1;
    },
  },
};
</script>

<style lang="scss" module>
.colContainer {
  display: flex;
  height: 500px;
  width: 100%;
}

.tableContainer {
  width: 40%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 10px;
  padding: 10px;
}

.buttonsContainer {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.scrollableContainer {
  max-height: 250px;
  margin: 0px 15px 15px 15px;
}
.scrollableContainerWL {
  max-height: 350px;
  margin: 0px 15px 15px 15px;
}
.table {
  width: 100%;
  td {
    max-width: 40px;
    overflow-x: auto;
    padding: 0px 5px;
  }
}

.header {
  position: sticky;
  z-index: 5;
  top: 0;
  background: white;
  height: 20px;
  font-weight: 500;
  cursor: pointer;
}

.customCheckBoxContainer {
  .customCheckbox {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    cursor: pointer;
  }
  input:checked + label > svg {
    animation: draw-checkbox ease-in-out 0.2s forwards;
  }
  label:active::after {
    background-color: #fbfbfb;
  }
  label {
    color: black;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    cursor: pointer;
    position: relative;
    display: block;
    &:after {
      content: '';
      height: 14px;
      width: 14px;
      float: left;
      border: 1px solid #9f9f9f;
      border-radius: 4px;
      transition: 0.15s all ease-out;
      background-color: white;
    }
  }
  input:checked + label:after {
    background-color: $blue;
    border: 1px solid $blue;
  }
  svg {
    position: absolute;
    left: 4px;
    top: 4px;
    stroke-dasharray: 33;
  }
  @keyframes draw-checkbox {
    0% {
      stroke-dashoffset: 33;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
}

.row {
  cursor: pointer;
  height: 22px;
  &:hover {
    background: $light-gray;
  }
}

.tdInline {
  display: inline-flex;
}

.arrow {
  margin: 7px 5px 0px 5px;

  &.asc {
    transform: rotate(180deg);
  }
  &.hidden {
    visibility: hidden;
  }
}

.button {
  border: 1px solid white;
  cursor: pointer;
  color: white;
  height: 50px;
  width: 85%;
  background: #2985bf;
  display: flex;
  justify-content: center;
  align-items: center;
  &.delete {
    background: $red;
    border: $red;
    height: 33px;
    width: 144px;
    margin: 10px;
  }
  &.green {
    background: $green;
    border: $green;
  }
  &.red {
    background: $red;
    border: $red;
  }
}

.title {
  font-size: 14px;
  display: flex;
  justify-content: center;
  font-weight: 600;
  margin-bottom: 20px;
}

.checkAllContainer {
  display: flex;
  align-items: center;
  height: 45px;
  width: 50%;
  cursor: pointer;
}

.checkAll {
  display: flex;
  align-items: center;
  margin: 15px 20px;
  justify-content: space-between;
}

.checkAllLabel {
  padding-left: 10px;
}

.comment {
  border: 0px;
  width: 90%;
}

::-webkit-scrollbar {
  width: 4px;
  height: 2px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $branchColor;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.search {
  margin: 0px 20px;
}

.svg {
  transform: rotate(90deg);
  margin-right: 7px;
}

.svgRight {
  transform: rotate(-90deg);
  margin-left: 10px;
}

.moveRight {
  padding-left: 5px;
}

.moveLeft {
  padding-right: 5px;
}

.loaderText {
  display: flex;
  justify-content: center;
  margin: 15px;
  font-size: 14px;
}
.loaderContainer {
  margin-top: 10%;
}
</style>

<style >
.usb .settings-modal_modal_3LaCC {
  width: 1000px !important;
  height: 600px !important;
}

input[type='file'] {
  display: none;
}
.custom-file-upload {
  border: 1px solid white;
  cursor: pointer;
  color: white;
  height: 36px;
  background: #2985bf;
  padding: 9px 12px;
  display: inline-block;
  margin: 15px 20px;
}
</style>
